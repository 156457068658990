import axios from 'axios'

export const signup = (newAccountObj) => axios.post("/api/account/register", newAccountObj)
export const login = (userAuth) => axios.post("/api/account/login", userAuth)
export const resendVerification = () => axios.post("/api/account/verify")
export const requestAccountApproval = () => axios.post("/api/account/request-approval")
export const changePassword = (passwordObj) => axios.post("/api/account/change-password", passwordObj)
export const logout = () => axios.get("/api/account/logout")
export const recoverPassword = (email) => axios.post("/api/account/recover", email)
export const reset = (token) => axios.post(`/api/account/reset/${token}`)
export const resetPassword = (token, password) => axios.post(`/api/account/reset-password/${token}`, password)
export const checkUser = () => axios.get("/api/account/check-user")

export const fetchBreedingPipelines = (query) => axios.post(`/api/breeding-pipeline/all`, query)
//Breeding Scheme ID is passed as an argument
export const fetchBreedingPipeline = (id) => axios.get(`/api/breeding-pipeline/${id}`)

export const fetchBreedingSchemeSettings = (id) => axios.get(`/api/scheme-settings/${id}`)
export const updateBreedingSchemeSettings = (id, breedingSchemeSettingsObj) => axios.put(`/api/scheme-settings/${id}`, breedingSchemeSettingsObj)

export const fetchBreedingSchemes = (query) => axios.post(`/api/breeding-scheme/all`, query)
export const createBreedingScheme = (breedingSchemeObj) => axios.post(`/api/breeding-scheme`, breedingSchemeObj)
export const updateBreedingScheme = (id, breedingSchemeObj) => axios.put(`/api/breeding-scheme/${id}`, breedingSchemeObj)
export const duplicateBreedingScheme = (id) => axios.post(`/api/breeding-scheme/duplicate/${id}`)
export const orderBreedingScheme = (orderObj) => axios.patch(`/api/breeding-scheme/`, orderObj)
export const removeBreedingScheme = (id) => axios.delete(`/api/breeding-scheme/${id}`)
export const exportBreedingSchemes = () => axios.post(`/api/breeding-scheme/export`)

export const fetchBreedingPool = (id) => axios.get(`/api/breeding-pool/${id}`)
export const updateBreedingPool = (id, poolObj) => axios.put(`/api/breeding-pool/${id}`, poolObj)
export const createBreedingPool = (breedingSchemeId, poolId) => axios.post(`/api/breeding-pool/${breedingSchemeId}/${poolId}`)
export const removeBreedingPool = (id) => axios.delete(`/api/breeding-pool/${id}`)

export const fetchBreedingStages = (breedingSchemeId, poolId) => axios.get(`/api/breeding-stage/${breedingSchemeId}/${poolId}`)
export const createBreedingStage = (breedingStageObj) => axios.post(`/api/breeding-stage`, breedingStageObj)
export const updateBreedingStage = (breedingStageObj) => axios.put(`/api/breeding-stage`, breedingStageObj)
export const duplicateBreedingStage = (id) => axios.post(`/api/breeding-stage/${id}`)
export const orderBreedingStage = (orderObj) => axios.patch(`/api/breeding-stage/`, orderObj)
export const addGenotypeTechnology = (id, technologyObj) => axios.post(`/api/genotype-technology/${id}`, technologyObj)
export const removeGenotypeTechnology = (id, breedingSchemeId) => axios.delete(`/api/genotype-technology/${id}/${breedingSchemeId}`)
export const addApplication = (id) => axios.post(`/api/genotype-technology-application/${id}`)
export const removeApplication = (id, breedingSchemeId) => axios.delete(`/api/genotype-technology-application/${id}/${breedingSchemeId}`)
export const addMarkerTraits = (id, traitObj) => axios.post(`/api/marker-trait/${id}`, traitObj)
export const removeMarkerTraits = (id, breedingSchemeId) => axios.delete(`/api/marker-trait/${id}/${breedingSchemeId}`)
export const addPhenotypeTraits = (id, traitObj) => axios.post(`/api/phenotype-trait/${id}`, traitObj)
export const removePhenotypeTraits = (id, breedingSchemeId) => axios.delete(`/api/phenotype-trait/${id}/${breedingSchemeId}`)
export const addTraits = (id, traitObj) => axios.post(`/api/trait/${id}`, traitObj)
export const updateTraits = (id, traitObj) => axios.put(`/api/trait/${id}`, traitObj)
export const removeTraits = (id, breedingSchemeId) => axios.delete(`/api/trait/${id}/${breedingSchemeId}`)
export const addCrossingPool = (id) => axios.post(`/api/crossing-pool/${id}`)
export const removeCrossingPool = (id, breedingSchemeId) => axios.delete(`/api/crossing-pool/${id}/${breedingSchemeId}`)
export const removeBreedingStage = (id, breedingSchemeId) => axios.delete(`/api/breeding-stage/${id}/${breedingSchemeId}`)
export const exportBreedingStages = (breedingSchemeId, poolId) => axios.post(`/api/breeding-stage/export/${breedingSchemeId}/${poolId}`)

export const fetchBreedingSchemeBlueprint = (id) => axios.get(`/api/breeding-scheme-blueprint/${id}`)
export const saveBreedingSchemeBlueprint = (id, breedingSchemeBlueprintObj) => axios.put(`/api/breeding-scheme-blueprint/${id}`, breedingSchemeBlueprintObj)
export const removeBreedingSchemeBlueprint = (id) => axios.delete(`/api/breeding-scheme-blueprint/${id}`)

export const fetchProfile = () => axios.get(`/api/user`)
export const fetchUsers = (query) => axios.post(`/api/user`, query)
export const updateUser = (id, userObj) => axios.put(`/api/user/${id}`, userObj)
export const updateProfile = (userObj) => axios.patch(`/api/user`, userObj)
export const approveUser = (id) => axios.post(`/api/user/${id}`)
export const removeUser = (id) => axios.delete(`/api/user/${id}`)
export const restoreUser = (id) => axios.patch(`/api/user/${id}`)

export const fetchUnsharedUsers = (queryObj) => axios.post(`/api/share/users`, queryObj)
export const fetchSharedUsers = (shareObj) => axios.post(`/api/share/shared-users`, shareObj)
export const shareContent = (shareObj) => axios.post(`/api/share/`, shareObj)
export const removeSharedUser = (shareObj) => axios.post(`/api/share/remove`, shareObj)

export const getSyncStatus = () => axios.get(`/api/sync-service`)
export const synchronizeNow = () => axios.post(`/api/sync-service`)