import { combineReducers } from 'redux'
import userAuth from './userAuth'
import changePassword from './changePassword'
import userSignup from './userSignup'
import userProfile from './userProfile'
import notification from './notification'
import breedingPipelines from './breedingPipelines'
import marketSegment from './marketSegment'
import productProfile from './productProfile'
import productProfileTraits from './productProfileTraits'
import breedingSchemeSettings from './breedingSchemeSettings'
import breedingScheme from './breedingScheme'
import breedingStage from './breedingStage'
import breedingSchemeFlowchart from './breedingSchemeFlowchart'
import breedingStageForms from './breedingStageForms'
import breedingStageTable from './breedingStageTable'
import breedingStageColumns from './breedingStageColumns'
import users from './users'
import breedingStageSpinner from './breedingStageSpinner'

export default combineReducers({
    userAuth,
    userSignup,
    changePassword,
    userProfile,
    notification,
    breedingPipelines,
    marketSegment,
    productProfile,
    productProfileTraits,
    breedingScheme,
    breedingSchemeSettings,
    breedingStage,
    breedingSchemeFlowchart,
    breedingStageForms,
    breedingStageTable,
    breedingStageColumns,
    users,
    breedingStageSpinner
})