import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector } from 'react-redux'
import { selectBreedingStagesIsLoading } from '../../../selectors/breedingStageSelector'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function BreedingStageSpinner() {
  const spinnerState = useSelector(selectBreedingStagesIsLoading)
  const classes = useStyles()
  return (
    <div>
      {spinnerState
        ? <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
        : <></>
      }
    </div>
  )
}

export default BreedingStageSpinner