import Box from '@material-ui/core/Box'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import ColumnTooltip from '../../molecules/ColumnTooltip'
import Link from '@material-ui/core/Link'
import IconButton from '@material-ui/core/IconButton'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import { tableColumnsGenerator } from '../../../utils'
import { BreedingStageColumnProperties } from '../../../constants'

const BreedingStageColumns = (props) => {
    const structuredColumns = tableColumnsGenerator(props.data, props.rowDepth, props.hiddenColumns)
    //Gets Columns name that are dependent on the constants value   
    const getColumnName = (columnName) => {
        const trimmedObjName = String(columnName).split(" ")
        switch (trimmedObjName[0]) {
            case '-':
                trimmedObjName.shift()
                return trimmedObjName.join(" ")
            case '_':
                trimmedObjName.shift()
                return trimmedObjName.join(" ")
            default:
                return columnName
        }
    }
    let headerRows = [];
    for (let idx = 0; idx < props.rowDepth; idx++) {
        headerRows.push(
            <TableRow data-height="27" key={idx}>
                {structuredColumns.map((columnObj, index) => {
                    const trimmedObjName = String(columnObj.name).split(" ")[0]
                    const columnProps = BreedingStageColumnProperties[trimmedObjName] ? BreedingStageColumnProperties[trimmedObjName] : BreedingStageColumnProperties['defaultColumn']
                    return columnObj.level === idx &&
                        <ColumnTooltip
                            key={index}
                            title={(columnProps.columnName ? columnProps.columnName : getColumnName(columnObj.name)) +
                                (['Traits', 'MarkerTrait', 'PhenotypeTrait'].includes(columnProps.modelName) ? ` (Trait ID: ${columnObj.code})` : "")}
                            description={columnProps ? columnProps.helpText : ""}
                        >
                            <TableCell
                                key={index}
                                data-a-h="center"
                                data-fill-color={columnProps.styles && columnProps.styles.backgroundColor ? columnProps.styles.backgroundColor.substring(1) : ""}
                                data-b-a-s="thin"
                                data-b-a-c="b3b3b3"
                                data-f-sz="13"
                                data-f-color={columnProps.styles && columnProps.styles.color ? columnProps.styles.color.substring(1) : ""}
                                data-f-bold="true"
                                data-a-v="middle"
                                data-a-wrap="true"
                                align="center"
                                className={columnProps.verticalOrientation && "verticalTitle"}
                                colSpan={columnObj.colSpan}
                                rowSpan={columnObj.rowSpan}
                                style={columnProps.styles && columnProps.styles}
                            >
                                <Box p={0} m={0} maxWidth={"inherit"} display="inline-block">
                                    {columnProps.secondaryAction &&
                                        <IconButton
                                            disabled={props.permission === 'VIEWER'}
                                            onClick={() => props[columnProps.secondaryAction.method](columnObj.groupId)}
                                            color="primary"
                                            style={{
                                                padding: "0px",
                                                fontSize: "13px",
                                                display: props.permission === 'VIEWER' ? "none" : "inline-flex"
                                            }}>
                                            {columnProps.secondaryAction.type === "Add" ? <AddCircleIcon style={{ fontSize: "20px" }} /> : <RemoveCircleIcon style={{ color: "#aa2e25", fontSize: "20px" }} />}
                                        </IconButton>}
                                    <Box display="inline-block" p={0} m={0} mx={1} style={{ minWidth: columnProps.modelName === "Traits" ? "145px" : "auto", maxWidth: "inherit" }}>
                                        {/* Only Traits columns can be renamed, hence why the handler is passed directly 
                                        ** TODO **
                                        For future flexibility, handlers should be passed dynamically 
                                        */}
                                        {columnProps.isEditable ?
                                            <Link href="#" onClick={() => props.permission === 'VIEWER' || props.handleOpenUpdateTraits(columnObj.groupId, columnObj.name, columnObj.value)}>
                                                <Typography variant="subtitle2" style={{ textTransform: "capitalize", maxWidth: "inherit", textOverflow: "ellipsis", overflow: "hidden" }}>
                                                    {columnProps.columnName ? columnProps.columnName : getColumnName(columnObj.name)}
                                                </Typography>
                                                {/* {columnProps.modelName === "Traits" && <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>$ {columnObj.value}</Typography>} */}
                                            </Link> :
                                            <Typography variant="subtitle2" style={{ textTransform: "capitalize", maxWidth: "inherit", textOverflow: "ellipsis", overflow: "hidden", fontWeight: "bold" }}>
                                                {columnProps.columnName ? columnProps.columnName : getColumnName(columnObj.name)}
                                            </Typography>
                                        }
                                    </Box>
                                    {columnProps.action &&
                                        <IconButton
                                            disabled={props.permission === 'VIEWER'}
                                            onClick={() => props[columnProps.action.method](columnObj.groupId)}
                                            color="primary"
                                            style={{
                                                padding: "0px",
                                                fontSize: "13px",
                                                display: props.permission === 'VIEWER' ? "none" : "inline-flex"
                                            }}>
                                            {columnProps.action.type === "Add" ? <AddCircleIcon style={{ fontSize: "20px" }} /> : <RemoveCircleIcon style={{ color: "#aa2e25", fontSize: "20px" }} />}
                                        </IconButton>}
                                </Box>
                            </TableCell>
                        </ColumnTooltip>
                }
                )}
            </TableRow >
        )
    }
    return headerRows
}

export default BreedingStageColumns