import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import Tour from 'reactour'
import Navigation from '../../organisms/Navigation'
import BreedingScheme from '../../organisms/BreedingScheme'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Button, Divider, Grid, Typography } from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'
import SystemUpdateAltTwoToneIcon from '@material-ui/icons/SystemUpdateAltTwoTone';
import AddBoxIcon from '@material-ui/icons/AddBox'
import RefreshIcon from '@material-ui/icons/Refresh';

const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: "0.94rem",
        maxWidth: "550px",
        '& .eTpeTG': {
            top: 8,
            right: 12,
            width: 10,
            height: 12
        },
    },
    speedDial: {
        position: 'fixed',
        '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
            bottom: theme.spacing(8),
            right: theme.spacing(4),
            zIndex: 1202,
            '& svg': {
                fontSize: "1.7rem"
            }
        },
        '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
            top: theme.spacing(2),
            left: theme.spacing(2),
            zIndex: 1202
        },
    },
}))

const BreedingSchemePage = () => {   
    const [isHelpOpen, setIsHelpOpen] = useState(false)
    const [isTourOpen, setIsTourOpen] = useState(false)
    const [reOpenTour, setReOpenTour] = useState(true)
    const userAuth = useSelector((state) => state.userAuth)
    const breedingSchemeState = useSelector((state) => state.breedingScheme)

    useEffect(() => {
        reOpenTour && setIsTourOpen(breedingSchemeState.openTour)
    }, [breedingSchemeState, reOpenTour])

    const handleTourOpen = () => {
        setIsTourOpen(true)
    }

    const steps = [
        {
            selector: '.appTour-step-0',
            action: () => setIsHelpOpen(false),
            content: ({ goTo, inDom }) => (
                <Grid container spacing={2}>
                    <Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
                        <Box alignContent="left">
                            <img src={process.env.PUBLIC_URL + '/logo-2.png'} alt="Excellence in Breeding Platform" width="190" />
                        </Box>
                    </Grid>
                    <Grid item xs={1}>
                        <Divider orientation="vertical" />
                    </Grid>
                    <Grid item xs={7}>
                        <Box textAlign="center" mt={2}>
                            <Typography>Hi <b>{userAuth.user.fullName}</b>,</Typography>
                            <br />
                            <Typography>
                                Let's get started with a quick guided tour of the <b>Breeding Scheme Manager</b>
                            </Typography>
                            <br />
                            <Typography hidden variant="body2">
                                This tool helps you to formalize the market segments,
                                product profiles and breeding schemes associated to their breeding scheme(s).
                            </Typography>
                        </Box>
                    </Grid>
                </Grid >
            ),
            style: {
                width: "550px"
            },
            position: 'center'
        }, {
            selector: '.appTour-step-1',
            action: () => setIsHelpOpen(false),
            content: ({ goTo, inDom }) => (
                <Box >
                    <Typography variant="button">User Profile</Typography>
                    <Divider orientation="horizontal" />
                    <Box mt={1}>
                        <Typography variant="body2">
                            Click on your name to update your profile
                        </Typography>
                        <Box textAlign="center" my={1}>
                            <img src={process.env.PUBLIC_URL + '/tour/profilePopover.png'} alt="Profile Popover" width="200" />
                        </Box>
                        <Typography variant="body2">
                            From the above form, you will be able to change password or logout from the current session
                        </Typography>
                    </Box>
                </Box >
            ),
            style: {
                width: "300px"
            },
            position: 'right',
        },
        {
            selector: '.appTour-step-2',
            action: () => setIsHelpOpen(false),
            content: ({ goTo, inDom }) => (
                <Box >
                    <Typography variant="button">Breeding Scheme Toolbar</Typography>
                    <Divider orientation="horizontal" />
                    <Box mt={1}>
                        <Typography variant="body2">
                            Here is a toolbar to perform different operations on this page.
                        </Typography>
                        <Box mt={1}>
                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <AddBoxIcon />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography variant="body2">
                                        Add new Breeding Scheme
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <RefreshIcon />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography variant="body2">
                                        Refresh/Reload Breeding Schemes
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <ToggleOffIcon />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography variant="body2">
                                        Toggle between all Breeding Schemes and yours only
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <GetAppIcon />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography variant="body2">
                                        Export Breeding Scheme to Excel (.xlsx)
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <SystemUpdateAltTwoToneIcon />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography variant="body2">
                                        Synchronize / Pull latest Breeding Pipeline and Traits data from Scriptoria.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box >
            ),
            style: {
                width: "300px"
            },
            position: 'right',
        },
        {
            selector: '.tourBtn',
            action: () => setIsHelpOpen(true),
            content: "To reopen this tour guide, click here.",
            position: 'left'
        },
        {
            selector: '.appTour-step-3',
            action: () => setIsHelpOpen(false),
            content: ({ goTo, inDom }) => (
                <Box>
                    <Typography variant="body1">Ok, let's start by adding our first Breeding Scheme.</Typography>
                    <Typography variant="body2" style={{ display: "flex", alignItems: "center" }}> Click&nbsp;<AddBoxIcon />&nbsp;to create a new Breeding Scheme.</Typography>
                    <Box textAlign="center" mt={2}>
                        <Button variant="contained" color="secondary" size="small" onClick={() => setIsTourOpen(false)}>End Tour</Button>
                    </Box>
                </Box>
            ),
            style: {
                width: "320px"
            },
            position: 'right'
        },
    ]

    const classes = useStyles()

    return (
        <>
            <Tour
                className={classes.root}
                closeWithMask={false}
                steps={steps}
                showNumber={false}
                maskSpace={10}
                rounded={10}
                isOpen={isTourOpen}
                onRequestClose={() => {
                    setReOpenTour(false)
                    setIsTourOpen(false)
                    setIsHelpOpen(false)
                }}
            />
            <Header />
            <Box my={2} pb={7} display="flex">
                <Navigation />
                <BreedingScheme handleTourOpen={handleTourOpen} />
            </Box>
            <Footer />
        </>
    )
}

export default BreedingSchemePage;