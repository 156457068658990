import { LOADING_BREEDING_STAGE, REMOVE_LOADING_BREEDING_STAGE, LOADING_BREEDING_STAGE_EXPORT, REMOVE_LOADING_BREEDING_STAGE_EXPORT } from '../constants/actionTypes';

const initialState = {
    isLoading: true,
    isExporting: false
}
const reducer = (breedingStageSpinner = initialState, action) => {
    switch (action.type) {
        case LOADING_BREEDING_STAGE:
            return { isLoading: true }
        case REMOVE_LOADING_BREEDING_STAGE:
            return { isLoading: false }
        case LOADING_BREEDING_STAGE_EXPORT:
            return { isLoading: true, isExporting: true }
        case REMOVE_LOADING_BREEDING_STAGE_EXPORT:
            return { isLoading: false, isExporting: false }
        default:
            return breedingStageSpinner
    }
}

export default reducer