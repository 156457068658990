export const countries = [
    { code: 'AD', label: 'Andorra', phone: '376' },
    { code: 'AE', label: 'United Arab Emirates', phone: '971' },
    { code: 'AF', label: 'Afghanistan', phone: '93' },
    { code: 'AG', label: 'Antigua and Barbuda', phone: '1-268' },
    { code: 'AI', label: 'Anguilla', phone: '1-264' },
    { code: 'AL', label: 'Albania', phone: '355' },
    { code: 'AM', label: 'Armenia', phone: '374' },
    { code: 'AO', label: 'Angola', phone: '244' },
    { code: 'AQ', label: 'Antarctica', phone: '672' },
    { code: 'AR', label: 'Argentina', phone: '54' },
    { code: 'AS', label: 'American Samoa', phone: '1-684' },
    { code: 'AT', label: 'Austria', phone: '43' },
    { code: 'AU', label: 'Australia', phone: '61', suggested: true },
    { code: 'AW', label: 'Aruba', phone: '297' },
    { code: 'AX', label: 'Alland Islands', phone: '358' },
    { code: 'AZ', label: 'Azerbaijan', phone: '994' },
    { code: 'BA', label: 'Bosnia and Herzegovina', phone: '387' },
    { code: 'BB', label: 'Barbados', phone: '1-246' },
    { code: 'BD', label: 'Bangladesh', phone: '880' },
    { code: 'BE', label: 'Belgium', phone: '32' },
    { code: 'BF', label: 'Burkina Faso', phone: '226' },
    { code: 'BG', label: 'Bulgaria', phone: '359' },
    { code: 'BH', label: 'Bahrain', phone: '973' },
    { code: 'BI', label: 'Burundi', phone: '257' },
    { code: 'BJ', label: 'Benin', phone: '229' },
    { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
    { code: 'BM', label: 'Bermuda', phone: '1-441' },
    { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
    { code: 'BO', label: 'Bolivia', phone: '591' },
    { code: 'BR', label: 'Brazil', phone: '55' },
    { code: 'BS', label: 'Bahamas', phone: '1-242' },
    { code: 'BT', label: 'Bhutan', phone: '975' },
    { code: 'BV', label: 'Bouvet Island', phone: '47' },
    { code: 'BW', label: 'Botswana', phone: '267' },
    { code: 'BY', label: 'Belarus', phone: '375' },
    { code: 'BZ', label: 'Belize', phone: '501' },
    { code: 'CA', label: 'Canada', phone: '1', suggested: true },
    { code: 'CC', label: 'Cocos (Keeling) Islands', phone: '61' },
    { code: 'CD', label: 'Congo, Democratic Republic of the', phone: '243' },
    { code: 'CF', label: 'Central African Republic', phone: '236' },
    { code: 'CG', label: 'Congo, Republic of the', phone: '242' },
    { code: 'CH', label: 'Switzerland', phone: '41' },
    { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
    { code: 'CK', label: 'Cook Islands', phone: '682' },
    { code: 'CL', label: 'Chile', phone: '56' },
    { code: 'CM', label: 'Cameroon', phone: '237' },
    { code: 'CN', label: 'China', phone: '86' },
    { code: 'CO', label: 'Colombia', phone: '57' },
    { code: 'CR', label: 'Costa Rica', phone: '506' },
    { code: 'CU', label: 'Cuba', phone: '53' },
    { code: 'CV', label: 'Cape Verde', phone: '238' },
    { code: 'CW', label: 'Curacao', phone: '599' },
    { code: 'CX', label: 'Christmas Island', phone: '61' },
    { code: 'CY', label: 'Cyprus', phone: '357' },
    { code: 'CZ', label: 'Czech Republic', phone: '420' },
    { code: 'DE', label: 'Germany', phone: '49', suggested: true },
    { code: 'DJ', label: 'Djibouti', phone: '253' },
    { code: 'DK', label: 'Denmark', phone: '45' },
    { code: 'DM', label: 'Dominica', phone: '1-767' },
    { code: 'DO', label: 'Dominican Republic', phone: '1-809' },
    { code: 'DZ', label: 'Algeria', phone: '213' },
    { code: 'EC', label: 'Ecuador', phone: '593' },
    { code: 'EE', label: 'Estonia', phone: '372' },
    { code: 'EG', label: 'Egypt', phone: '20' },
    { code: 'EH', label: 'Western Sahara', phone: '212' },
    { code: 'ER', label: 'Eritrea', phone: '291' },
    { code: 'ES', label: 'Spain', phone: '34' },
    { code: 'ET', label: 'Ethiopia', phone: '251' },
    { code: 'FI', label: 'Finland', phone: '358' },
    { code: 'FJ', label: 'Fiji', phone: '679' },
    { code: 'FK', label: 'Falkland Islands (Malvinas)', phone: '500' },
    { code: 'FM', label: 'Micronesia, Federated States of', phone: '691' },
    { code: 'FO', label: 'Faroe Islands', phone: '298' },
    { code: 'FR', label: 'France', phone: '33', suggested: true },
    { code: 'GA', label: 'Gabon', phone: '241' },
    { code: 'GB', label: 'United Kingdom', phone: '44' },
    { code: 'GD', label: 'Grenada', phone: '1-473' },
    { code: 'GE', label: 'Georgia', phone: '995' },
    { code: 'GF', label: 'French Guiana', phone: '594' },
    { code: 'GG', label: 'Guernsey', phone: '44' },
    { code: 'GH', label: 'Ghana', phone: '233' },
    { code: 'GI', label: 'Gibraltar', phone: '350' },
    { code: 'GL', label: 'Greenland', phone: '299' },
    { code: 'GM', label: 'Gambia', phone: '220' },
    { code: 'GN', label: 'Guinea', phone: '224' },
    { code: 'GP', label: 'Guadeloupe', phone: '590' },
    { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
    { code: 'GR', label: 'Greece', phone: '30' },
    { code: 'GS', label: 'South Georgia and the South Sandwich Islands', phone: '500' },
    { code: 'GT', label: 'Guatemala', phone: '502' },
    { code: 'GU', label: 'Guam', phone: '1-671' },
    { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
    { code: 'GY', label: 'Guyana', phone: '592' },
    { code: 'HK', label: 'Hong Kong', phone: '852' },
    { code: 'HM', label: 'Heard Island and McDonald Islands', phone: '672' },
    { code: 'HN', label: 'Honduras', phone: '504' },
    { code: 'HR', label: 'Croatia', phone: '385' },
    { code: 'HT', label: 'Haiti', phone: '509' },
    { code: 'HU', label: 'Hungary', phone: '36' },
    { code: 'ID', label: 'Indonesia', phone: '62' },
    { code: 'IE', label: 'Ireland', phone: '353' },
    { code: 'IL', label: 'Israel', phone: '972' },
    { code: 'IM', label: 'Isle of Man', phone: '44' },
    { code: 'IN', label: 'India', phone: '91' },
    { code: 'IO', label: 'British Indian Ocean Territory', phone: '246' },
    { code: 'IQ', label: 'Iraq', phone: '964' },
    { code: 'IR', label: 'Iran, Islamic Republic of', phone: '98' },
    { code: 'IS', label: 'Iceland', phone: '354' },
    { code: 'IT', label: 'Italy', phone: '39' },
    { code: 'JE', label: 'Jersey', phone: '44' },
    { code: 'JM', label: 'Jamaica', phone: '1-876' },
    { code: 'JO', label: 'Jordan', phone: '962' },
    { code: 'JP', label: 'Japan', phone: '81', suggested: true },
    { code: 'KE', label: 'Kenya', phone: '254' },
    { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
    { code: 'KH', label: 'Cambodia', phone: '855' },
    { code: 'KI', label: 'Kiribati', phone: '686' },
    { code: 'KM', label: 'Comoros', phone: '269' },
    { code: 'KN', label: 'Saint Kitts and Nevis', phone: '1-869' },
    { code: 'KP', label: "Korea, Democratic People's Republic of", phone: '850' },
    { code: 'KR', label: 'Korea, Republic of', phone: '82' },
    { code: 'KW', label: 'Kuwait', phone: '965' },
    { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
    { code: 'KZ', label: 'Kazakhstan', phone: '7' },
    { code: 'LA', label: "Lao People's Democratic Republic", phone: '856' },
    { code: 'LB', label: 'Lebanon', phone: '961' },
    { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
    { code: 'LI', label: 'Liechtenstein', phone: '423' },
    { code: 'LK', label: 'Sri Lanka', phone: '94' },
    { code: 'LR', label: 'Liberia', phone: '231' },
    { code: 'LS', label: 'Lesotho', phone: '266' },
    { code: 'LT', label: 'Lithuania', phone: '370' },
    { code: 'LU', label: 'Luxembourg', phone: '352' },
    { code: 'LV', label: 'Latvia', phone: '371' },
    { code: 'LY', label: 'Libya', phone: '218' },
    { code: 'MA', label: 'Morocco', phone: '212' },
    { code: 'MC', label: 'Monaco', phone: '377' },
    { code: 'MD', label: 'Moldova, Republic of', phone: '373' },
    { code: 'ME', label: 'Montenegro', phone: '382' },
    { code: 'MF', label: 'Saint Martin (French part)', phone: '590' },
    { code: 'MG', label: 'Madagascar', phone: '261' },
    { code: 'MH', label: 'Marshall Islands', phone: '692' },
    { code: 'MK', label: 'Macedonia, the Former Yugoslav Republic of', phone: '389' },
    { code: 'ML', label: 'Mali', phone: '223' },
    { code: 'MM', label: 'Myanmar', phone: '95' },
    { code: 'MN', label: 'Mongolia', phone: '976' },
    { code: 'MO', label: 'Macao', phone: '853' },
    { code: 'MP', label: 'Northern Mariana Islands', phone: '1-670' },
    { code: 'MQ', label: 'Martinique', phone: '596' },
    { code: 'MR', label: 'Mauritania', phone: '222' },
    { code: 'MS', label: 'Montserrat', phone: '1-664' },
    { code: 'MT', label: 'Malta', phone: '356' },
    { code: 'MU', label: 'Mauritius', phone: '230' },
    { code: 'MV', label: 'Maldives', phone: '960' },
    { code: 'MW', label: 'Malawi', phone: '265' },
    { code: 'MX', label: 'Mexico', phone: '52' },
    { code: 'MY', label: 'Malaysia', phone: '60' },
    { code: 'MZ', label: 'Mozambique', phone: '258' },
    { code: 'NA', label: 'Namibia', phone: '264' },
    { code: 'NC', label: 'New Caledonia', phone: '687' },
    { code: 'NE', label: 'Niger', phone: '227' },
    { code: 'NF', label: 'Norfolk Island', phone: '672' },
    { code: 'NG', label: 'Nigeria', phone: '234' },
    { code: 'NI', label: 'Nicaragua', phone: '505' },
    { code: 'NL', label: 'Netherlands', phone: '31' },
    { code: 'NO', label: 'Norway', phone: '47' },
    { code: 'NP', label: 'Nepal', phone: '977' },
    { code: 'NR', label: 'Nauru', phone: '674' },
    { code: 'NU', label: 'Niue', phone: '683' },
    { code: 'NZ', label: 'New Zealand', phone: '64' },
    { code: 'OM', label: 'Oman', phone: '968' },
    { code: 'PA', label: 'Panama', phone: '507' },
    { code: 'PE', label: 'Peru', phone: '51' },
    { code: 'PF', label: 'French Polynesia', phone: '689' },
    { code: 'PG', label: 'Papua New Guinea', phone: '675' },
    { code: 'PH', label: 'Philippines', phone: '63' },
    { code: 'PK', label: 'Pakistan', phone: '92' },
    { code: 'PL', label: 'Poland', phone: '48' },
    { code: 'PM', label: 'Saint Pierre and Miquelon', phone: '508' },
    { code: 'PN', label: 'Pitcairn', phone: '870' },
    { code: 'PR', label: 'Puerto Rico', phone: '1' },
    { code: 'PS', label: 'Palestine, State of', phone: '970' },
    { code: 'PT', label: 'Portugal', phone: '351' },
    { code: 'PW', label: 'Palau', phone: '680' },
    { code: 'PY', label: 'Paraguay', phone: '595' },
    { code: 'QA', label: 'Qatar', phone: '974' },
    { code: 'RE', label: 'Reunion', phone: '262' },
    { code: 'RO', label: 'Romania', phone: '40' },
    { code: 'RS', label: 'Serbia', phone: '381' },
    { code: 'RU', label: 'Russian Federation', phone: '7' },
    { code: 'RW', label: 'Rwanda', phone: '250' },
    { code: 'SA', label: 'Saudi Arabia', phone: '966' },
    { code: 'SB', label: 'Solomon Islands', phone: '677' },
    { code: 'SC', label: 'Seychelles', phone: '248' },
    { code: 'SD', label: 'Sudan', phone: '249' },
    { code: 'SE', label: 'Sweden', phone: '46' },
    { code: 'SG', label: 'Singapore', phone: '65' },
    { code: 'SH', label: 'Saint Helena', phone: '290' },
    { code: 'SI', label: 'Slovenia', phone: '386' },
    { code: 'SJ', label: 'Svalbard and Jan Mayen', phone: '47' },
    { code: 'SK', label: 'Slovakia', phone: '421' },
    { code: 'SL', label: 'Sierra Leone', phone: '232' },
    { code: 'SM', label: 'San Marino', phone: '378' },
    { code: 'SN', label: 'Senegal', phone: '221' },
    { code: 'SO', label: 'Somalia', phone: '252' },
    { code: 'SR', label: 'Suriname', phone: '597' },
    { code: 'SS', label: 'South Sudan', phone: '211' },
    { code: 'ST', label: 'Sao Tome and Principe', phone: '239' },
    { code: 'SV', label: 'El Salvador', phone: '503' },
    { code: 'SX', label: 'Sint Maarten (Dutch part)', phone: '1-721' },
    { code: 'SY', label: 'Syrian Arab Republic', phone: '963' },
    { code: 'SZ', label: 'Swaziland', phone: '268' },
    { code: 'TC', label: 'Turks and Caicos Islands', phone: '1-649' },
    { code: 'TD', label: 'Chad', phone: '235' },
    { code: 'TF', label: 'French Southern Territories', phone: '262' },
    { code: 'TG', label: 'Togo', phone: '228' },
    { code: 'TH', label: 'Thailand', phone: '66' },
    { code: 'TJ', label: 'Tajikistan', phone: '992' },
    { code: 'TK', label: 'Tokelau', phone: '690' },
    { code: 'TL', label: 'Timor-Leste', phone: '670' },
    { code: 'TM', label: 'Turkmenistan', phone: '993' },
    { code: 'TN', label: 'Tunisia', phone: '216' },
    { code: 'TO', label: 'Tonga', phone: '676' },
    { code: 'TR', label: 'Turkey', phone: '90' },
    { code: 'TT', label: 'Trinidad and Tobago', phone: '1-868' },
    { code: 'TV', label: 'Tuvalu', phone: '688' },
    { code: 'TW', label: 'Taiwan, Province of China', phone: '886' },
    { code: 'TZ', label: 'United Republic of Tanzania', phone: '255' },
    { code: 'UA', label: 'Ukraine', phone: '380' },
    { code: 'UG', label: 'Uganda', phone: '256' },
    { code: 'US', label: 'United States', phone: '1', suggested: true },
    { code: 'UY', label: 'Uruguay', phone: '598' },
    { code: 'UZ', label: 'Uzbekistan', phone: '998' },
    { code: 'VA', label: 'Holy See (Vatican City State)', phone: '379' },
    { code: 'VC', label: 'Saint Vincent and the Grenadines', phone: '1-784' },
    { code: 'VE', label: 'Venezuela', phone: '58' },
    { code: 'VG', label: 'British Virgin Islands', phone: '1-284' },
    { code: 'VI', label: 'US Virgin Islands', phone: '1-340' },
    { code: 'VN', label: 'Vietnam', phone: '84' },
    { code: 'VU', label: 'Vanuatu', phone: '678' },
    { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
    { code: 'WS', label: 'Samoa', phone: '685' },
    { code: 'XK', label: 'Kosovo', phone: '383' },
    { code: 'YE', label: 'Yemen', phone: '967' },
    { code: 'YT', label: 'Mayotte', phone: '262' },
    { code: 'ZA', label: 'South Africa', phone: '27' },
    { code: 'ZM', label: 'Zambia', phone: '260' },
    { code: 'ZW', label: 'Zimbabwe', phone: '263' },
];

export const genderOptions = [
    { name: "Male", value: "Male" },
    { name: "Female", value: "Female" }
]

export const sharingPermissions = [
    { name: "Can view", value: "VIEWER" },
    { name: "Can edit", value: "EDITOR" },
    { name: "Make owner", value: "OWNER" },
]

export const researchCenters = [
    {
        center: "Africa Rice Center",
        location: "Abidjan - Côte d'Ivoire"
    },
    {
        center: "Center for International Forestry Research",
        location: "Bogor - Indonesia"
    },
    {
        center: "CGIAR System Organization",
        location: "Montpellier, France"
    },
    {
        center: "International Center for Agricultural Research in the Dry Areas",
        location: "Beirut - Lebanon"
    },
    {
        center: "International Crops Research Institute for the Semi-Arid Tropics",
        location: "Patancheru - India"
    },
    {
        center: "International Food Policy Research Institute",
        location: "Washington DC - USA"
    },
    {
        center: "International Institute of Tropical Agriculture",
        location: "Ibadan - Nigeria"
    },
    {
        center: "International Livestock Research Institute",
        location: "Co-hosted by Kenya and Ethiopia"
    },
    {
        center: "International Maize and Wheat Improvement Center",
        location: "Texcoco - Mexico"
    },
    {
        center: "International Potato Center",
        location: "Lima - Peru"
    },
    {
        center: "International Rice Research Institute",
        location: "Los Baños - Philippines"
    },
    {
        center: "International Water Management Institute",
        location: "Colombo - Sri Lanka"
    },
    {
        center: "The Alliance of Bioversity International and the International Center for Tropical Agriculture (CIAT)",
        location: "Cali, Colombia"
    },
    // {
    //     center: "The Alliance of Bioversity International and the International Center for Tropical Agriculture (CIAT)",
    //     location: "Rome, Italy"
    // },
    {
        center: "World Agroforestry (ICRAF)",
        location: "Nairobi - Kenya"
    },
    {
        center: "WorldFish",
        location: "Penang - Malaysia"
    },
]

export const marketSegmentLookups =
{
    center: {
        "Africa-Rice": "Africa-Rice",
        CIMMYT: "CIMMYT",
        CIP: "CIP",
        "CIAT-Bio": "CIAT-Bio",
        CIFOR: "CIFOR",
        ICARDA: "ICARDA",
        ICRISAT: "ICRISAT",
        IITA: "IITA",
        ILRI: "ILRI",
        IRRI: "IRRI",
        IWMI: "IWMI",
        WF: "WF"
    },
    crop: {
        Apple: "Apple",
        Banana: "Banana",
        Beans: "Beans",
        Blackberry: "Blackberry",
        Blueberry: "Blueberry",
        Canola: "Canola",
        Cassava: "Cassava",
        Chickpea: "Chickpea",
        "Egg-Plant": "Egg-Plant",
        Forages: "Forages",
        Groundnut: "Groundnut",
        Kiwi: "Kiwi",
        Kumquat: "Kumquat",
        Lettuce: "Lettuce",
        Maize: "Maize",
        Nectarine: "Nectarine",
        Orange: "Orange",
        Peach: "Peach",
        Plantain: "Plantain",
        Plum: "Plum",
        Potato: "Potato",
        Raspberry: "Raspberry",
        Rice: "Rice",
        Sorghum: "Sorghum",
        Strawberry: "Strawberry",
        "Sweet-Potato": "Sweet-Potato",
        Tomato: "Tomato",
        Wheat: "Wheat"
    },
    region: {
        "-": "-",
        East: "East",
        North: "North",
        South: "South",
        West: "West",
        "East-South": "East-South",
        "East-West": "East-West",
        "East-North": "East-North",
        "North-West": "North-West",
        "North-East": "North-East",
        "North-South": "North-South",
        "West-South": "West-South",
        "West-East": "West-East",
        "West-North": "West-North",
        "South-East": "South-East",
        "South-West": "South-West",
        "South-North": "South-North",
        Latin: "Latin"
    },
    subRegion: {
        "-": "-",
        East: "East",
        North: "North",
        South: "South",
        West: "West"
    },
    continent: {
        "-": "-",
        Africa: "Africa",
        America: "America",
        Caribbean: "Caribbean",
        Asia: "Asia",
        Europe: "Europe",
        Australia: "Australia",
        Antarctica: "Antarctica"
    },
    cropType: {
        Food: "Food",
        Forage: "Forage",
        Industrial: "Industrial"
    },
    color: {
        Black: "Black",
        White: "White",
        Red: "Red",
        Yellow: "Yellow",
        Green: "Green",
        Orange: "Orange",
        Blue: "Blue",
        Purple: "Purple",
        Pink: "Pink",
        Tan: "Tan",
        None: "None"
    },
    where: {
        "-": "-",
        "Sea-level (<500)": "Sea-level (<500)",
        "Low (500-2000)": "Low (500-2000)",
        "Mid (2000-3000)": "Mid (2000-3000)",
        "High (3000-5500)": "High (3000-5500)",
        "Extreme (>5500)": "Extreme (>5500)"
    },
    how: {
        "Deep-water": "Deep-water",
        Waterlogged: "Waterlogged",
        "High-rainfall(=wet)": "High-rainfall(=wet)",
        "Medium-rainfall": "Medium-rainfall",
        "Drought-prone": "Drought-prone",
        Dry: "Dry",
        "Heat-stressed": "Heat-stressed",
        "Normal-temperature": "Normal-temperature",
        "Cold-stressed": "Cold-stressed",
        Hand: "hand",
        "Machine-harvest": "Machine-harvest",
        Rainfed: "Rainfed",
        "Partially-irrigated": "Partially-irrigated",
        Irrigated: "Irrigated",
        "Direct-seeded": "Direct-seeded",
        Transplanted: "Transplanted",
        "Main-season": "Main-season",
        "Dry-season": "Dry-season",
        "Main-crop": "Main-crop",
        "Second-crop": "Second-crop",
        Kharif: "Kharif",
        Rabi: "Rabi",
        Winter: "Winter",
        Short: "Short",
        Medium: "Medium",
        Long: "Long"
    },
    maturity: {
        "-": "-",
        Early: "Early",
        "Early-intermediate": "Early-intermediate",
        Intermediate: "Intermediate",
        "Intermediate-late": "Intermediate-late",
        Late: "Late",
    },
}

export const productProfileTraitLookups = {
    traitType: {
        Quality: "Quality",
        Agronomic: "Agronomic",
        Morphological: "Morphological",
        Phenological: "Phenological",
        AbioticStress: "Abiotic Stress",
        BioticStress: "Biotic Stress"
    },
    unit1: {
        Milligrams: "Milligrams",
        Grams: "Grams",
        Kilograms: "Kilograms",
        Tons: "Tons",
        Centimeters: "Centimeters",
        Meters: "Meters",
        "Score; best=min": "Score; best=min",
        "Score; best=max": "Score; best=max",
        "%": "%",
        "% over check": "% over check",
        Days: "Days",
        Other: "Other",
        Brix: "Brix",
        Degrees: "Degrees",
        mm: "mm",
        "mm²": "mm²",
        cm: "cm",
        "cm²": "cm²",
        m: "m",
        "m²": "m²",
        Acre: "Acre",
        Hectare: "Hectare",
        "Seed, flower, inflorescence": "Seed, flower, inflorescence",
        "Plant, ear, bunch, branch": "Plant, ear, bunch, branch",
        "Spike, cob": "Spike, cob",
        "Tuber, fruit": "Tuber, fruit",
        "-": "-"
    },
    unit2: {
        per: "per",
        "-": "-",
    },
    unit3: {
        Milligrams: "Milligrams",
        Grams: "Grams",
        Kilograms: "Kilograms",
        Tons: "Tons",
        Centimeters: "Centimeters",
        Meters: "Meters",
        "Score; best=min": "Score; best=min",
        "Score; best=max": "Score; best=max",
        "%": "%",
        "% over check": "% over check",
        Days: "Days",
        Other: "Other",
        Brix: "Brix",
        Degrees: "Degrees",
        mm: "mm",
        "mm²": "mm²",
        cm: "cm",
        "cm²": "cm²",
        m: "m",
        "m²": "m²",
        Acre: "Acre",
        Hectare: "Hectare",
        "Seed, flower, inflorescence": "Seed, flower, inflorescence",
        "Plant, ear, bunch, branch": "Plant, ear, bunch, branch",
        "Spike, cob": "Spike, cob",
        "Tuber, fruit": "Tuber, fruit",
        "-": "-"
    }
}
export const breedingSchemeLookups = {
    tier: {
        "Category 1": "Category 1",
        "Category 2": "Category 2",
        "Category 3": "Category 3",
        "Category 4": "Category 4"
    },
    // Please note that, if new productType is added, it should also be configured in rendering BreedingStages
    productType: {
        Clone: "Clone",
        Hybrid: "Hybrid",
        Line: "Line",
        "Open-Pollinated Variety": "Open-Pollinated Variety",
        Synthetic: "Synthetic"
    }

}

export const breedingSchemeSettingLookups = {
    level1: ["-", "HSfamilies", "testers", "donors"],
    level2: ["-", "FSfamilies", "F1.or.cross", "crosses", "F1"],
    level3: ["-", "Fx(x>1)ind", "lines"],
    level4: ["-", "sublines", "submaterials"],
}

export const genotypingTechnologyLookups = {
    "lowDensitySNPAssay": "Low-density SNP assay",
    "midDensitySNPArray": "Mid-density SNP array",
    "highDensitySNPArray": "High-density SNP array",
    "genotypingBySequencing": "Genotyping-by-Sequencing",
    "wholeGenomeSequencing": "Whole-genome sequencing"
}

const gridColorPalette = {
    selectableColumns: {
        background: "#019688",
        color: "#FFFFFF"
    },
    numberColumns: {
        background: "#FFFFFF",
        color: "rgba(0, 0, 0, 0.87)"
    },
    calculatedFields: {
        background: "#e3e3e3",
        color: "#000"
    },
    additionalFields: "#FFF2CC"
}

export const BreedingStageColumnProperties = {
    actions: {
        columnName: "Activities",
        verticalOrientation: true,
        styles: {
            width: "auto",
            verticalAlign: "middle",
            color: "#0071C0",
            fontWeight: "bold",
        },
        helpText: "Breeding stage activities"
    },
    stageNo: {
        columnName: "No.",
        verticalOrientation: false,
        styles: {
            backgroundColor: "#F2F1F2",
            color: "#0071C0",
            fontWeight: "bold",
            position: "sticky",
            minWidth: "57px",
            padding: 0,
            left: 70,
            zIndex: 10
        },
        helpText: "Breeding stage no.",
        editable: "never"
    },
    section: {
        columnName: " ",
        verticalOrientation: false,
        styles: {
            backgroundColor: "#F2F1F2",
            color: "#0071C0",
            fontWeight: "bold",
            position: "sticky",
            minWidth: "57px",
            padding: 0,
            left: 70,
            zIndex: 10
        },
        helpText: "Breeding Stage No.",
        editable: "never"
    },
    BreedingPopulation: {
        columnName: "BREEDING POPULATION",
        verticalOrientation: false,
        styles: {
            backgroundColor: "#F2F1F2",
            color: "#0071C0",
            fontWeight: "bolder",
        },
        helpText: "Overall structure of the breeding scheme and breeding activities carried out at the different stages of the product development process."
    },
    Divider1: {
        columnName: " ",
        verticalOrientation: false,
        styles: {
            backgroundColor: "#FFFFFF",
            color: "#0071C0",
            fontWeight: "bold",
            minWidth: "30px",
            maxWidth: "30px",
            border: 0
        },
        helpText: "",
        editable: "never"
    },
    PopulationStructure: {
        columnName: "CROSSING AND POPULATION STRUCTURE",
        verticalOrientation: false,
        styles: {
            backgroundColor: "#F2F1F2",
            color: "#0071C0",
            fontWeight: "bolder",
            maxWidth: "300px"
        },
        helpText: "Structure of the breeding population at the different stages of the product development process, including crossing block composition and mating design."
    },
    Divider2: {
        columnName: " ",
        verticalOrientation: false,
        styles: {
            backgroundColor: "#FFFFFF",
            color: "#0071C0",
            fontWeight: "bold",
            minWidth: "30px",
            maxWidth: "30px",
            border: 0
        },
        helpText: "",
        editable: "never"
    },
    MaterialIncrease: {
        columnName: "SEED / MATERIAL INCREASE",
        verticalOrientation: false,
        styles: {
            backgroundColor: "#F2F1F2",
            color: "#0071C0",
            fontWeight: "bolder",
        },
        helpText: "Seed multiplication and material increase at the different stages of the product development process."
    },
    Divider3: {
        columnName: " ",
        verticalOrientation: false,
        styles: {
            backgroundColor: "#FFFFFF",
            color: "#0071C0",
            fontWeight: "bold",
            minWidth: "30px",
            maxWidth: "30px",
            border: 0
        },
        helpText: "",
        editable: "never"
    },
    Genotyping: {
        columnName: "GENOTYPING",
        verticalOrientation: false,
        action: {
            type: "Add",
            method: "handleAddGenotypingTechnology"
        },
        styles: {
            backgroundColor: "#F2F1F2",
            color: "#0071C0",
            fontWeight: "bolder",
        },
        helpText: "Genotyping technologies and their applications at the different stages of the product development process."
    },
    Divider4: {
        columnName: " ",
        verticalOrientation: false,
        styles: {
            backgroundColor: "#FFFFFF",
            color: "#0071C0",
            fontWeight: "bold",
            minWidth: "30px",
            maxWidth: "30px",
            border: 0
        },
        helpText: "",
        editable: "never"
    },
    MarkerSelection: {
        columnName: "MARKER-ASSISTED SELECTION",
        verticalOrientation: false,
        styles: {
            backgroundColor: "#F2F1F2",
            color: "#0071C0",
            fontWeight: "bolder",
            minWidth: "220px"
        },
        helpText: "Traits selected with Marker-Assisted Selection at the different stages of the product development process."
    },
    Divider5: {
        columnName: " ",
        verticalOrientation: false,
        styles: {
            backgroundColor: "#FFFFFF",
            color: "#0071C0",
            fontWeight: "bold",
            minWidth: "30px",
            maxWidth: "30px",
            border: 0
        },
        helpText: "",
        editable: "never"
    },
    PhenotypeScreening: {
        columnName: "PHENOTYPE SCREENING",
        verticalOrientation: false,
        action: {
            type: "Add",
            method: "handleAddPhenotypeTraits"
        },
        styles: {
            backgroundColor: "#F2F1F2",
            color: "#0071C0",
            fontWeight: "bolder"
        },
        helpText: "Traits selected based on fast / visual phenotypic screening (of highly heritable traits), for example to quickly and effectively cull a large fraction of the tested material when collection and analysis of experimental data is inefficient or infeasible."
    },
    Divider6: {
        columnName: " ",
        verticalOrientation: false,
        styles: {
            backgroundColor: "#FFFFFF",
            color: "#0071C0",
            fontWeight: "bold",
            minWidth: "30px",
            maxWidth: "30px",
            border: 0
        },
        helpText: "",
        editable: "never"
    },
    FieldTrialDesign: {
        columnName: "TRIAL DESIGN",
        verticalOrientation: false,
        styles: {
            backgroundColor: "#F2F1F2",
            color: "#0071C0",
            fontWeight: "bolder",
        },
        helpText: "Experimental design including check strategy used for data collection at different stages of the product development process."
    },
    Divider7: {
        columnName: " ",
        verticalOrientation: false,
        styles: {
            backgroundColor: "#FFFFFF",
            color: "#0071C0",
            fontWeight: "bold",
            minWidth: "30px",
            maxWidth: "30px",
            border: 0
        },
        helpText: "",
        editable: "never"
    },
    SelectionStrategy: {
        columnName: "PHENOTYPING, EVALUATION & SELECTION",
        verticalOrientation: false,
        styles: {
            backgroundColor: "#F2F1F2",
            color: "#0071C0",
            fontWeight: "bolder",
        },
        helpText: "Traits selected based on the analysis of experimental data collected as described in the TRIAL DESIGN section. This includes the phenotyping strategy, statistical evaluation, and selection value and method for advancement and recycling."
    },
    Divider8: {
        columnName: " ",
        verticalOrientation: false,
        styles: {
            backgroundColor: "#FFFFFF",
            color: "#0071C0",
            fontWeight: "bold",
            minWidth: "30px",
            maxWidth: "30px",
            border: 0
        },
        helpText: "",
        editable: "never"
    },
    Advancement: {
        columnName: "ADVANCEMENT",
        verticalOrientation: false,
        styles: {
            backgroundColor: "#F2F1F2",
            color: "#0071C0",
            fontWeight: "bolder",
        },
        helpText: "Advancement strategy at each stage of the product development process."
    },
    CrossingStrategy: {
        columnName: "CROSSING",
        verticalOrientation: false,
        styles: {
            backgroundColor: "#F2F1F2",
            color: "#0071C0",
            fontWeight: "bolder",
        },
        //helpText: "This is column help text in popover."
    },
    productDevelopmentStage: {
        columnName: "Product Development Stage",
        verticalOrientation: true,
        styles: {
            backgroundColor: gridColorPalette.selectableColumns.background,
            color: gridColorPalette.selectableColumns.color,
            minWidth: "58px",
        },
        lookup: {
            "-": "-",
            "Trait Discovery": "Trait Discovery",
            "Trait Deployment": "Trait Deployment",
            "Crossing & Screening": "Crossing & Screening",
            "Early Testing": "Early Testing",
            "Late Testing": "Late Testing",
            "On-Farm Verification": "On-Farm Testing"
        },
        modelName: "BreedingPopulation",
        helpText: "Stage within the product development process. The product development process involves identification, creation, and advancement of products for a market segment. Note that the population improvement component is considered part of the entire product development process here."
    },
    year: {
        columnName: "Year",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFFFFF" },
        modelName: "BreedingPopulation",
        //helpText: ""
    },
    season: {
        columnName: "Season",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFFFFF" },
        modelName: "BreedingPopulation",
        //helpText: "Season when this material is planted."
    },
    stageName: {
        columnName: "Stage Name",
        verticalOrientation: true,
        styles: {            
            minWidth: "51px",
        },
        // lookup: {},
        freeSolo: true, //Enables a freeSolo autocomplete where user can type free text aswell
        maxLength: 4, //Max length of chars
        modelName: "BreedingPopulation",
        helpText: "The conventional stage name commonly used by breeders."
    },
    activity: {
        columnName: "Activity",
        verticalOrientation: true,
        styles: {
            backgroundColor: gridColorPalette.selectableColumns.background,
            color: gridColorPalette.selectableColumns.color,
            minWidth: "100px",
            whiteSpace: "no-wrap"
        },
        //freeSolo: true,
        multi: true,
        lookup: {
            "-": "-",
            Advancement: "Advancement",
            Crossing: "Crossing",
            "Field Testing": "Field Testing",
            Genotyping: "Genotyping",
            Increase: "Increase",
            MAS: "MAS",
            Recycling: "Recycling",
            Screening: "Screening"
        },
        modelName: "BreedingPopulation",
        helpText: "The breeding activities carried out at each stage of the breeding process. Multiple options can be chosen."
    },
    generation: {
        columnName: "Generation",
        verticalOrientation: true,
        styles: {            
            minWidth: "51px",
        },
        //lookup: {},
        freeSolo: true, //Enables a freeSolo autocomplete where user can type free text aswell
        modelName: "BreedingPopulation",
        helpText: "Generation of the breeding material at each stage of the breeding process (e.g., Parents, F1, F2, S0, S1, C1, C2, …)."
    },
    derivedFrom: {
        columnName: "Derived From",
        verticalOrientation: true,
        styles: {            
            minWidth: "51px",
        },
        //lookup: {},
        freeSolo: true, //Enables a freeSolo autocomplete where user can type free text aswell
        modelName: "BreedingPopulation",
        helpText: "The generation from which the breeding material at the current stage originated."
    },
    breedingMethod: {
        columnName: "Breeding Method",
        verticalOrientation: true,
        styles: {
            backgroundColor: gridColorPalette.selectableColumns.background,
            color: gridColorPalette.selectableColumns.color,
            minWidth: "130px",
        },
        lookup: {
            "-": "-",
            "Bulk Method": "Bulk Method",
            "Clonal Selection": "Clonal Selection",
            "Doubled Haploids": "Doubled Haploids",
            "Ear-to-Row": "Ear-to-Row",
            "Mass Selection": "Mass Selection",
            "Pedigree Selection": "Pedigree Selection",
            "Single-Seed Descent": "Single-Seed Descent"
        },
        freeSolo: true,
        modelName: "BreedingPopulation",
        helpText: "Method of reproduction and selection."
    },

    // CROSSING & POPULATION STRUCTURE

    numberOfParents: {
        columnName: "Number of Parents",
        verticalOrientation: false,
        styles: {
            backgroundColor: "#F2F1F2",
            color: "#0071C0",
        },
        //helpText: ""
    },

    recycledWithinPipeline: {
        columnName: "Recycled Within Pipeline",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFFFFF" },
        modelName: "PopulationStructure",
        helpText: "Number of parents recycled from recently tested breeding material."
    },

    fromAnotherCGIARPipeline: {
        columnName: "From Another CGIAR Pipeline",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFFFFF" },
        modelName: "PopulationStructure",
        helpText: "Number of parents introduced from another CGIAR breeding pipeline (with similar target product profile)."
    },

    fromNonCGIARPipelines: {
        columnName: "From Non-CGIAR Pipelines",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFFFFF" },
        modelName: "PopulationStructure",
        helpText: "Number of parents introduced from a non-CGIAR breeding program (with similar target product profile)."
    },

    fromOlderVarieties: {
        columnName: "From Old Breeding Material & Varieties",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFFFFF" },
        modelName: "PopulationStructure",
        helpText: "Number parents derived from breeding material dating several years back or old varieties."
    },

    fromLandracesOrOPVs: {
        columnName: "From Landraces & other Non-Elite Material",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFFFFF" },
        modelName: "PopulationStructure",
        helpText: "Number of parents from landraces and other unadapted non-elite material."
    },

    fromTDD: {
        columnName: "From TD&D",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFFFFF" },
        modelName: "PopulationStructure",
        helpText: "Number of parents introduced from trait discovery and deployment."
    },

    totalNumberOfParents: {
        columnName: "Total Number of Parents",
        verticalOrientation: true,
        styles: { backgroundColor: gridColorPalette.calculatedFields.background, color: gridColorPalette.calculatedFields.color },
        modelName: "PopulationStructure",
        helpText: "",
        editable: "never"
    },

    matingDesign: {
        columnName: "Mating Design",
        verticalOrientation: true,
        styles: {
            backgroundColor: gridColorPalette.selectableColumns.background,
            color: gridColorPalette.selectableColumns.color,
            minWidth: "130px",
        },
        lookup: {
            "-": "-",
            "Full Diallel": "Full Diallel",
            "Full Factorial": "Full Factorial",
            GPCP: "GPCP",
            "Half Diallel": "Half Diallel",
            "Incomplete Diallel": "Incomplete Diallel",
            "Incomplete Factorial": "Incomplete Factorial",
            "Optimal Contribution Selection": "Optimal Contribution Selection",
            "Optimal Cross Selection": "Optimal Cross Selection",
            Polycross: "Polycross",
            Random: "Random",
            "Sparse Testcross": "Sparse Testcross",
            Testcross: "Testcross",
            Topcross: "Topcross",
            "?": "?"
        },
        freeSolo: true,
        modelName: "PopulationStructure",
        helpText: ""
    },

    numberOfCrosses: {
        columnName: "Number of Crosses",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFFFFF" },
        modelName: "PopulationStructure",
        helpText: "Number of crosses made between parents or maintained at the current breeding stage (e.g., when family selection is applied)."
    },

    numberOfIndividualsPerCross: {
        columnName: "Number of Individuals per Cross",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFFFFF" },
        modelName: "PopulationStructure",
        helpText: "Average number of individuals generated from a cross or maintained at the current breeding stage."
    },

    numberOfLinesPerIndividual: {
        columnName: "Number of Lines per Individual",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFFFFF" },
        modelName: "PopulationStructure",
        helpText: "Number of Lines generated from a single individual, such as an F2. (LINES)"
    },

    numberOfSublinesPerLine: {
        columnName: "Number of Sublines per Line",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFFFFF" },
        modelName: "PopulationStructure",
        helpText: "Number of sublines created from a line in a pedigree breeding program. (LINES)."
    },

    numberOfTesters: {
        columnName: "Number of Testers",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFFFFF" },
        modelName: "PopulationStructure",
        helpText: "Number of testers to be used for testcrossing with another pool."
    },

    numberOfTestcrosses: {
        columnName: "Number of Test Crosses",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFFFFF" },
        modelName: "PopulationStructure",
        helpText: "Number of testcrosses generated with testers from another pool."
    },

    numberOfHybrids: {
        columnName: "Number of Hybrids",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFFFFF" },
        modelName: "PopulationStructure",
        helpText: "Number of hybrids which are not testcrosses."
    },

    typeOfParent: {
        columnName: "Type of Parent",
        verticalOrientation: true,
        styles: {
            backgroundColor: gridColorPalette.selectableColumns.background,
            color: gridColorPalette.selectableColumns.color,
            minWidth: "100px",
        },
        lookup: {
            "-": "-",
            Clone: "Clone",
            "Full-Sib Family": "Full-Sib Family",
            "Half-Sib Family": "Half-Sib Family",
            Line: "Line",
            "?": "?"
        },
        freeSolo: true,
        modelName: "PopulationStructure",
        helpText: "Breeding material used as parents."
    },

    numberOfHalfSibFamilies: {
        columnName: "Number of Half-Sib Families",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFFFFF" },
        modelName: "PopulationStructure",
        helpText: "Number of half-sib families under evaluation."
    },

    numberOfFullSibFamilies: {
        columnName: "Number of Full-Sib Families",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFFFFF" },
        modelName: "PopulationStructure",
        helpText: "Number of full-sib families under evaluation."
    },

    numberOfPopulations: {
        columnName: "Number of Populations",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFFFFF" },
        modelName: "PopulationStructure",
        helpText: "Number of Populations under evaluation, specifically number of candidate populations to become Open-Pollinated Varieties."
    },

    numberOfComponentsPerSynthetic: {
        columnName: "Number of Components per Synthetic",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFFFFF" },
        modelName: "PopulationStructure",
        helpText: "Number of lines, clones, or families combined to create the synthetic cultivar."
    },

    numberOfSynthetics: {
        columnName: "Number of Synthetics",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFFFFF" },
        modelName: "PopulationStructure",
        helpText: "Number of synthetics under evaluation."
    },

    numberOfIndividuals: {
        columnName: "Number of Individuals",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFFFFF" },
        modelName: "PopulationStructure",
        helpText: "Average number of individuals maintained at the current breeding stage. To be filled only when the number of crosses and individuals per cross maintained at the current stage is unknown."
    },
    
    totalNumberOfGenotypes: {
        columnName: "Total Number of Genotypes",
        verticalOrientation: true,
        styles: { backgroundColor: gridColorPalette.calculatedFields.background, color: gridColorPalette.calculatedFields.color },
        modelName: "PopulationStructure",
        helpText: "",
        editable: "never"
    },

    // ---------- SEED/MATERIAL INCREASE --------------

    multiplicationUnit: {
        columnName: "Multiplication Unit",
        verticalOrientation: true,
        styles: {
            backgroundColor: gridColorPalette.selectableColumns.background,
            color: gridColorPalette.selectableColumns.color,
            minWidth: "135px",
        },
        lookup: {
            "-": "-",
            "b: Backcross": "b: Backcross",
            "c: Clone": "c: Clone",
            "d: DH": "d: DH",
            "fs: Full-Sib Family": "fs: Full-Sib Family",
            "h: Hybrid": "h: Hybrid",
            "hs: Half-Sib Family": "hs: Half-Sib Family",
            "i: Individual Plant": "i: Individual Plant",
            "l: Line": "l: Line",
            "p: Population": "p: Population",
            "pc: Polycross": "pc: Polycross",
            "s: Synthetic": "s: Synthetic",
            "sl: Subline": "sl: Subline",
            "t: Testcross": "t: Testcross",
            "tc: Topcross": "tc: Topcross",
            "?": "?"
        },
        freeSolo: true,
        modelName: "MaterialIncrease",
        helpText: "Genetic material increased at each stage of the breeding process."
    },

    multiplicationTechnology: {
        columnName: "Multiplication Technology",
        verticalOrientation: true,
        styles: {
            backgroundColor: gridColorPalette.selectableColumns.background,
            color: gridColorPalette.selectableColumns.color,
            minWidth: "100px",
        },
        lookup: {
            "-": "-",
            RGA: "RGA",
            SAH: "SAH",
            "Speed Breeding": "Speed Breeding",
            "?": "?"
        },
        freeSolo: true,
        modelName: "MaterialIncrease",
        helpText: "Is any specific technology applied for optimized propagation or rapid generation turnover?"
    },

    typeOfExperimentalUnit: {
        columnName: "Type of Experimental Unit",
        verticalOrientation: true,
        styles: {
            backgroundColor: gridColorPalette.selectableColumns.background,
            color: gridColorPalette.selectableColumns.color,
            minWidth: "100px",
        },
        lookup: {
            "-": "-",
            Dish: "Dish",
            Plot: "Plot",
            Pot: "Pot",
            Row: "Row",
            Tray: "Tray",
            "?": "?"
        },
        freeSolo: true,
        modelName: "MaterialIncrease",
        helpText: "Type of experimental unit used for multiplication."
    },

    length: {
        columnName: "Length",
        verticalOrientation: true,
        styles: {           
            minWidth: "51px",
        },
        //lookup: {},
        freeSolo: true, //Enables a freeSolo autocomplete where user can type free text aswell        
        modelName: "MaterialIncrease",
        helpText: "Length and unit of length (e.g., 1 m)."
    },

    width: {
        columnName: "Width",
        verticalOrientation: true,
        styles: {            
            minWidth: "51px",
        },
        //lookup: {},
        freeSolo: true, //Enables a freeSolo autocomplete where user can type free text aswell        
        modelName: "MaterialIncrease",
        helpText: "Width and unit of width (e.g., 1 m)."
    },

    numberOfPlantsPerExperimentalUnit: {
        columnName: "Number of Plants / Experimental Unit",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFFFFF" },
        modelName: "MaterialIncrease",
        helpText: "Number of plants used for multiplication per experimental unit."
    },

    numberOfExperimentalUnits: {
        columnName: "Number of Experimental Units",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFFFFF" },
        modelName: "MaterialIncrease",
        helpText: "Number of experimental units used for multiplication."
    },

    // ----------- GENOTYPING ------------
    Technologies: {
        columnName: "Technologies",
        verticalOrientation: false,
        styles: { backgroundColor: "#F2F1F2" },
        helpText: "Genotyping technology. Multiple technologies can be chosen."
    },
    // --- TODO ---
    // Figure out how Geneotyping Technologies can be added dynamically if not list all in the column def

    "lowDensitySNPAssay": {
        columnName: "Low-density SNP assay",
        verticalOrientation: false,
        action: {
            type: "Add",
            method: "handleAddApplication"
        },
        secondaryAction: {
            type: "Remove",
            method: "handleConfirmRemoveTechnology"
        },
        styles: { backgroundColor: "#F2F1F2" },
        //helpText: "This is column help text in popover."
    },

    "midDensitySNPArray": {
        columnName: "Mid-density SNP array",
        verticalOrientation: false,
        action: {
            type: "Add",
            method: "handleAddApplication"
        },
        secondaryAction: {
            type: "Remove",
            method: "handleConfirmRemoveTechnology"
        },
        styles: { backgroundColor: "#F2F1F2" },
        //helpText: "This is column help text in popover."
    },

    "highDensitySNPArray": {
        columnName: "High-density SNP array",
        verticalOrientation: false,
        action: {
            type: "Add",
            method: "handleAddApplication"
        },
        secondaryAction: {
            type: "Remove",
            method: "handleConfirmRemoveTechnology"
        },
        styles: { backgroundColor: "#F2F1F2" },
        //helpText: "This is column help text in popover."
    },

    "genotypingBySequencing": {
        columnName: "Genotyping-by-Sequencing",
        verticalOrientation: false,
        action: {
            type: "Add",
            method: "handleAddApplication"
        },
        secondaryAction: {
            type: "Remove",
            method: "handleConfirmRemoveTechnology"
        },
        styles: { backgroundColor: "#F2F1F2" },
        //helpText: "This is column help text in popover."
    },

    "wholeGenomeSequencing": {
        columnName: "whole-Genome Sequencing",
        verticalOrientation: false,
        action: {
            type: "Add",
            method: "handleAddApplication"
        },
        secondaryAction: {
            type: "Remove",
            method: "handleConfirmRemoveTechnology"
        },
        styles: { backgroundColor: "#F2F1F2" },
        //helpText: "This is column help text in popover."
    },

    noOfMarkers: {
        columnName: "Number of Markers",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFFFFF" },
        modelName: "Technology",
        helpText: "Number of markers called with the selected technology."
    },

    application: {
        verticalOrientation: true,
        styles: {
            backgroundColor: gridColorPalette.selectableColumns.background,
            color: gridColorPalette.selectableColumns.color,
            minWidth: "120px",
            textTransform: "capitalize"
        },
        action: {
            type: "Remove",
            method: "handleConfirmRemoveApplication"
        },
        lookup: {
            "-": "-",
            "Genetic Diversity Analysis": "Genetic Diversity Analysis",
            "Genetic Mapping": "",
            "Genome-Wide Association Studies (GWAS)": "Genome-Wide Association Studies (GWAS)",
            "Genomic Selection (GS)": "Genomic Selection (GS)",
            "Heterozygosity Assessment": "Heterozygosity Assessment",
            "Identity Verification": "Identity Verification",
            "Marker-Assisted Backcrossing (MABC)": "Marker-Assisted Backcrossing (MABC)",
            "Marker-Assisted Selection (MAS)": "Marker-Assisted Selection (MAS)",
            "Parent Verification": "Parent Verification",
            "Ploidy Verification": "Ploidy Verification",
            "Population Structure Analysis": "Population Structure Analysis",
            "Purity Assessment": "Purity Assessment",
            "QTL Mapping": "QTL Mapping",
            "Trait Introgression": "Trait Introgression",
            "?": "?"
        },
        freeSolo: true,
        modelName: "Application",
        helpText: "Utilization of genotyping technology. Multiple application columns can be added for each technology."
    },

    // ----------- MARKER-ASSISTED SELECTION ------------

    noOfMarkerAssistedSelection: {
        columnName: "Number of Markers",
        verticalOrientation: false,
        action: {
            type: "Add",
            method: "handleAddMarkerTraits"
        },
        styles: { backgroundColor: "#F2F1F2", color: "#0071C0" },
        helpText: "Number of markers used for marker-assisted selection."
    },

    MarkerTraits: {
        columnName: "Marker Traits",
        verticalOrientation: false,
        styles: { backgroundColor: "#F2F1F2" },
        helpText: "Trait undergoing marker-assisted selection."
    },

    noOfMarkersAssisted: {
        //columnName: "Number of Experimental Units",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFFFFF" },
        modelName: "MarkerTrait",
        helpText: ""
    },

    "_": {
        //columnName: "Number of Experimental Units",
        verticalOrientation: true,
        styles: {
            backgroundColor: "#FFFFFF",
            maxWidth: "200px",
            overflow: "hidden",
        },
        action: {
            type: "Remove",
            method: "handleConfirmRemoveMarkerTraits"
        },
        modelName: "MarkerTrait",
        helpText: "Trait undergoing marker-assisted selection."
    },

    // ----------- PHENOTYPE SCREENING ------------

    PhenotypeTraits: {
        //columnName: "Phenotype Traits",
        verticalOrientation: false,
        styles: { backgroundColor: "#F2F1F2" },
        helpText: "Trait undergoing phenotype screening."
    },

    "-": {
        verticalOrientation: false,
        action: {
            type: "Remove",
            method: "handleConfirmRemovePhenotypeTraits"
        },
        styles: { backgroundColor: "#F2F1F2", color: "#0071C0" },
        modelName: "PhenotypeTrait",
        helpText: "Trait undergoing phenotype screening."
    },

    phenotypeCondition: {
        columnName: "Condition",
        verticalOrientation: true,
        styles: {
            backgroundColor: gridColorPalette.selectableColumns.background,
            color: gridColorPalette.selectableColumns.color,
            minWidth: "100px",
        },
        lookup: {
            "-": "-",
            Field: "Field",
            Glasshouse: "Glasshouse",
            Greenhouse: "Greenhouse",
            "Growth Chamber": "Growth Chamber",
            "On-Farm": "On-Farm",
            Screenhouse: "Screenhouse",
            "?": "?"
        },
        freeSolo: true,
        modelName: "PhenotypeTrait",
        helpText: "Experimental condition under which material is screened."
    },

    phenotypeTypeOfExpUnit: {
        columnName: "Type of Experimental Unit",
        verticalOrientation: true,
        styles: {
            backgroundColor: gridColorPalette.selectableColumns.background,
            color: gridColorPalette.selectableColumns.color,
            minWidth: "100px",
        },
        lookup: {
            "-": "-",
            Dish: "Dish",
            Plot: "Plot",
            Pot: "Pot",
            Row: "Row",
            Tray: "Tray",
            "?": "?"
        },
        freeSolo: true,
        modelName: "PhenotypeTrait",
        helpText: "Type of experimental unit used for screening."
    },

    phenotypeLength: {
        columnName: "Length",
        verticalOrientation: true,
        styles: {           
            minWidth: "51px",
        },
        //lookup: {},
        freeSolo: true, //Enables a freeSolo autocomplete where user can type free text aswell        
        modelName: "PhenotypeTrait",
        helpText: "Length and unit of length (e.g., 1 m)."
    },

    phenotypeWidth: {
        columnName: "Width",
        verticalOrientation: true,
        styles: {           
            minWidth: "51px",
        },
        //lookup: {},
        freeSolo: true, //Enables a freeSolo autocomplete where user can type free text aswell        
        modelName: "PhenotypeTrait",
        helpText: "Width and unit of width (e.g., 1 m)."
    },

    phenoNoOfPlantsPerExpUnit: {
        columnName: "Number of Plants per Experimental Unit",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFFFFF", },
        modelName: "PhenotypeTrait",
        helpText: "Number of plants screened per experimental unit."
    },
    phenotypeNoOfLocations: {
        columnName: "Number of Locations",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFFFFF" },
        modelName: "PhenotypeTrait",
        helpText: "Number of locations in which material is screened."
    },
    phenotypeNoOfRepPerLocation: {
        columnName: "Number of Replicates per Location",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFFFFF" },
        modelName: "PhenotypeTrait",
        helpText: "Number of replicates per location. Use values lower than 1 to indicate sparse testing."
    },

    // ----------- FIELD TRIAL DESIGN ------------

    experimentalUnit: {
        columnName: "Experimental Unit",
        verticalOrientation: false,
        styles: {
            backgroundColor: "#F2F1F2",
            color: "#0071C0",
        },
        helpText: ""
    },

    layout: {
        columnName: "Layout",
        verticalOrientation: false,
        styles: {
            backgroundColor: "#F2F1F2",
            color: "#0071C0",
        },
        helpText: ""
    },

    checkStrategy: {
        columnName: "Check Strategy",
        verticalOrientation: false,
        styles: {
            backgroundColor: "#F2F1F2",
            color: "#0071C0",
        },
        helpText: ""
    },

    fieldCondition: {
        columnName: "Condition",
        verticalOrientation: true,
        styles: {
            backgroundColor: gridColorPalette.selectableColumns.background,
            color: gridColorPalette.selectableColumns.color,
            minWidth: "100px",
        },
        lookup: {
            "-": "-",
            Field: "Field",
            Glasshouse: "Glasshouse",
            Greenhouse: "Greenhouse",
            "Growth Chamber": "Growth Chamber",
            "On-Farm": "On-Farm",
            Screenhouse: "Screenhouse",
            "?": "?"
        },
        freeSolo: true,
        modelName: "FieldTrialDesign",
        helpText: "Experimental condition under which material is evaluated."
    },

    fieldTypeOfExperimentalUnit: {
        columnName: "Type of Experimental Unit",
        verticalOrientation: true,
        styles: {
            backgroundColor: gridColorPalette.selectableColumns.background,
            color: gridColorPalette.selectableColumns.color,
            minWidth: "100px",
        },
        lookup: {
            "-": "-",
            Dish: "Dish",
            Plot: "Plot",
            Pot: "Pot",
            Row: "Row",
            Tray: "Tray",
            "?": "?"
        },
        freeSolo: true,
        modelName: "FieldTrialDesign",
        helpText: "Type of experimental unit used in trial."
    },

    fieldLength: {
        columnName: "Length",
        verticalOrientation: true,
        styles: {            
            minWidth: "51px",
        },
        //lookup: {},
        freeSolo: true, //Enables a freeSolo autocomplete where user can type free text aswell        
        modelName: "FieldTrialDesign",
        helpText: "Length and unit of length (e.g., 1 m)."
    },

    fieldWidth: {
        columnName: "Width",
        verticalOrientation: true,
        styles: {          
            minWidth: "51px",
        },
        //lookup: {},
        freeSolo: true, //Enables a freeSolo autocomplete where user can type free text aswell        
        modelName: "FieldTrialDesign",
        helpText: "Width and unit of width (e.g., 1 m)."
    },

    fieldNumberOfPlantsPerExperimentalUnit: {
        columnName: "Number of Plants/Experimental Unit",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFFFFF" },
        modelName: "FieldTrialDesign",
        helpText: "Number of plants tested per experimental unit."
    },

    experimentalDesign: {
        columnName: "Experimental Design",
        verticalOrientation: true,
        styles: {
            backgroundColor: gridColorPalette.selectableColumns.background,
            color: gridColorPalette.selectableColumns.color,
            minWidth: "100px",
        },
        lookup: {
            "-": "-",
            Alpha: "Alpha",
            Augmented: "Augmented",
            CBD: "CBD",
            "Latin Square": "Latin Square",
            None: "None",
            RCBD: "RCBD",
            "Split-Plot": "Split-Plot",
            "?": "?"
        },
        freeSolo: true,
        modelName: "FieldTrialDesign",
        helpText: "Experimental design within location."
    },
    numberOfLocations: {
        columnName: "Number of Locations",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFFFFF" },
        modelName: "FieldTrialDesign",
        helpText: "Number of locations in which material is tested."
    },
    numberOfReplicatesPerLocation: {
        columnName: "Number of Replicates/Location",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFFFFF" },
        modelName: "FieldTrialDesign",
        helpText: "Number of replicates per location. Use values lower than 1 to indicate sparse testing."
    },
    numberOfExperimentalUnitsPerLocation: {
        columnName: "Number of Experimental Units/Location",
        verticalOrientation: true,
        styles: { backgroundColor: gridColorPalette.calculatedFields.background, color: gridColorPalette.calculatedFields.color },
        modelName: "FieldTrialDesign",
        editable: "never",
        helpText: ""
    },
    totalNumberOfExperimentalUnits: {
        columnName: "Total Number of Experimental Units",
        verticalOrientation: true,
        styles: { backgroundColor: gridColorPalette.calculatedFields.background, color: gridColorPalette.calculatedFields.color },
        modelName: "FieldTrialDesign",
        editable: "never",
        helpText: ""
    },
    numberOfSpatialCheckGenotypes: {
        columnName: "Number of Spatial Check Genotypes",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFFFFF" },
        modelName: "FieldTrialDesign",
        helpText: "Number of different genotypes used as spatial checks (e.g., for statistical analysis, spatial connectivity)"
    },
    numberOfTemporalCheckGenotypes: {
        columnName: "Number of Temporal Check Genotypes",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFFFFF" },
        modelName: "FieldTrialDesign",
        helpText: "Number of different genotypes used as temporal checks for trial connectivity across seasons and years."
    },
    checkUnitsPerLocation: {
        columnName: "Check Units/Location",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFFFFF" },
        modelName: "FieldTrialDesign",
        helpText: "Number of experimental units per location allocated to check genotypes."
    },
    percentageOfCheckUnitsPerLocation: {
        columnName: "Percentage of Check Units/Location",
        verticalOrientation: true,
        styles: { backgroundColor: gridColorPalette.calculatedFields.background, color: gridColorPalette.calculatedFields.color },
        modelName: "FieldTrialDesign",
        editable: "never",
        helpText: ""
    },
    trialsConnectedVia: {
        columnName: "Trials Connected via",
        verticalOrientation: true,
        styles: {
            backgroundColor: gridColorPalette.selectableColumns.background,
            color: gridColorPalette.selectableColumns.color,
            minWidth: "120px"
        },
        lookup: {
            "-": "-",
            "Spatial Checks": "Spatial Checks",
            "Temporal Checks": "Temporal Checks",
            GRM: "GRM",
            Entries: "Entries",
            "Benchmark Checks": "Benchmark Checks"
        },
        freeSolo: true,
        multi: true,
        modelName: "FieldTrialDesign",
        helpText: "Strategies used to ensure connectivity between field trials across locations, seasons, and years."
    },

    // ---------- ADVANCEMENT ----------------

    advancedUnit: {
        columnName: "Advanced Unit",
        verticalOrientation: true,
        styles: {
            backgroundColor: gridColorPalette.selectableColumns.background,
            color: gridColorPalette.selectableColumns.color,
            minWidth: "135px",
        },
        lookup: {
            "-": "-",
            "b: Backcross": "b: Backcross",
            "c: Clone": "c: Clone",
            "d: DH": "d: DH",
            "fs: Full-Sib Family": "fs: Full-Sib Family",
            "h: Hybrid": "h: Hybrid",
            "hs: Half-Sib Family": "hs: Half-Sib Family",
            "i: Individual Plant": "i: Individual Plant",
            "l: Line": "l: Line",
            "p: Population": "p: Population",
            "pc: Polycross": "pc: Polycross",
            "s: Synthetic": "s: Synthetic",
            "sl: Subline": "sl: Subline",
            "t: Testcross": "t: Testcross",
            "tc: Topcross": "tc: Topcross",
            "?": "?"
        },
        freeSolo: true,
        modelName: "Advancement",
        helpText: "Genetic material advanced to the next stage of the breeding process."
    },

    numberOfUnitsAdvanced: {
        columnName: "Number Of Units Advanced",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFFFFF" },
        modelName: "Advancement",
        helpText: "Indicate which fraction of the breeding material is advanced to the next breeding stage. Filling one column auto-fills the remaining ones."
    },

    percentageOfUnitsAdvanced: {
        columnName: "Percentage of Units Advanced",
        verticalOrientation: true,
        styles: { backgroundColor: gridColorPalette.calculatedFields.background, color: gridColorPalette.calculatedFields.color },
        modelName: "Advancement",
        editable: "never",
        helpText: "Fraction of the breeding material advanced to the next breeding stage."
    },

    QualityTraits: {
        columnName: "Quality",
        verticalOrientation: false,
        action: {
            type: "Add",
            method: "handleAddQualityTrait"
        },
        styles: { backgroundColor: "#F2F1F2" },
        //helpText: "This is column help text in popover."
    },
    AgronomicTraits: {
        columnName: "Agronomic",
        verticalOrientation: false,
        action: {
            type: "Add",
            method: "handleAddAgronomicTrait"
        },
        styles: { backgroundColor: "#F2F1F2" },
        //helpText: "This is column help text in popover."
    },
    MorphologicalTraits: {
        columnName: "Morphological",
        verticalOrientation: false,
        action: {
            type: "Add",
            method: "handleAddMorphologicalTrait"
        },
        styles: { backgroundColor: "#F2F1F2" },
        //helpText: "This is column help text in popover."
    },
    PhenologicalTraits: {
        columnName: "Phenological",
        verticalOrientation: false,
        action: {
            type: "Add",
            method: "handleAddPhenologicalTrait"
        },
        styles: { backgroundColor: "#F2F1F2" },
        //helpText: "This is column help text in popover."
    },
    AbioticStressTraits: {
        columnName: "Abiotic Stress",
        verticalOrientation: false,
        action: {
            type: "Add",
            method: "handleAddAbioticStressTrait"
        },
        styles: { backgroundColor: "#F2F1F2" },
        //helpText: "This is column help text in popover."
    },
    BioticStressTraits: {
        columnName: "Biotic Stress",
        verticalOrientation: false,
        action: {
            type: "Add",
            method: "handleAddBioticStressTrait"
        },
        styles: { backgroundColor: "#F2F1F2" },
        //helpText: "This is column help text in popover."
    },

    selectionSeasonCost: {
        columnName: "Selection Season Cost",
        verticalOrientation: true,
        styles: { backgroundColor: gridColorPalette.calculatedFields.background, color: gridColorPalette.calculatedFields.color },
        prepend: "$",
        modelName: "RecyclingStrategy",
        helpText: "Approximate selection cost. Don't modify, is a guiding number.",
        editable: "never"
    },

    CrossingPools: {
        columnName: "Pools",
        verticalOrientation: false,
        action: {
            type: "Add",
            method: "handleAddCrossingPool"
        },
        styles: { backgroundColor: "#F2F1F2" },
        //helpText: "This is column help text in popover."
    },
    Pool: {
        verticalOrientation: false,
        action: {
            type: "Remove",
            method: "handleConfirmRemovePools"
        },
        styles: { backgroundColor: "#FFFFFF", borderLeft: "2px solid #aaa" },
        helpText: <div>1st Column: #Parents <br /> 2nd Column: #Crosses <br />3rd Column: #progeny/cross</div>
    },
    noOfParents: {
        columnName: "#Parents",
        verticalOrientation: false,
        styles: { backgroundColor: gridColorPalette.numberColumns.background, color: gridColorPalette.numberColumns.color },
        modelName: "CrossingPool",
        helpText: " "
    },
    noOfCrosses: {
        columnName: "#Crosses",
        verticalOrientation: false,
        styles: { backgroundColor: gridColorPalette.numberColumns.background, color: gridColorPalette.numberColumns.color },
        modelName: "CrossingPool",
        helpText: " "
    },
    noOfProgenyPerCross: {
        columnName: "#Progeny/Cross",
        verticalOrientation: false,
        styles: { backgroundColor: gridColorPalette.numberColumns.background, color: gridColorPalette.numberColumns.color },
        modelName: "CrossingPool",
        helpText: " "
    },

    // PHENOTYPE, EVALUATION AND SELECTION
    noOfTestLocation: {
        columnName: "No of Test Location per Trait",
        verticalOrientation: true,
        styles: { },
        modelName: "Trait",
        //helpText: "This is column help text in popover."
    },
    evaluationUnit: {
        columnName: "Phenotype/Evaluation Unit",
        verticalOrientation: true,
        //freeSolo: true,
        styles: {
            backgroundColor: gridColorPalette.selectableColumns.background,
            color: gridColorPalette.selectableColumns.color,
            paddingLeft: "2px",
            //maxWidth: "40px",
            //overflow: "hidden"
        },
        lookup: {
            "-": "-",
            "b:": "b: Backcross",
            "c:": "c: Clone",
            "d: DH": "d: DH",
            "fs:": "fs: Full-Sib Family",
            "h:": "h: Hybrid",
            "hs:": "hs: Half-Sib Family",
            "i:": "i: Individual Plant",
            "l:": "l: Line",
            "p:": "p: Population",
            "pc:": "pc: Polycross",
            "s:": "s: Synthetic",
            "sl:": "sl: Subline",
            "t:": "t: Testcross",
            "tc:": "tc: Topcross",
        },
        modelName: "Trait",
        //helpText: "This is column help text in popover."
    },
    methodOfStatEvaluation: {
        columnName: "Method of Statistical Evaluation",
        verticalOrientation: true,
        //freeSolo: true,
        styles: {
            backgroundColor: gridColorPalette.selectableColumns.background,
            color: gridColorPalette.selectableColumns.color,
            paddingLeft: "2px",
            //minWidth: "140px"
        },
        lookup: {
            "-": "-",
            "a:": "a: Average",
            "b:": "b: BLUE",
            "bp:": "bp: BLUP",
            "gb:": "gb: Genomic BLUP",
            "i:": "i: Single Record",
            "pb:": "pb: Pedigree BLUP"
        },
        modelName: "Trait",
        //helpText: "This is column help text in popover."
    },
    advancementValue: {
        columnName: "Advancement Value",
        verticalOrientation: true,
        //freeSolo: true,
        styles: {
            backgroundColor: gridColorPalette.selectableColumns.background,
            color: gridColorPalette.selectableColumns.color,
            paddingLeft: "2px",
            //minWidth: "120px"
        },
        lookup: {
            "-": "-",
            "a:": "a: Average",
            "b:": "b: BLUE",
            "bv:": "bv: BV",
            "g:": "g: GCA",
            "gb:": "gb: GEBV",
            "gc:": "gc: GEGCA",
            "gg:": "gg: GEGV",
            "gs:": "gs: GESCA",
            "gv:": "gv: GV",
            "i:": "i: Single Record",
            "s:": "s: SCA"
        },
        modelName: "Trait",
        //helpText: "This is column help text in popover."
    },
    advancementSelection: {
        columnName: "Advancement Selection Method",
        verticalOrientation: true,
        //freeSolo: true,
        styles: {
            backgroundColor: gridColorPalette.selectableColumns.background,
            color: gridColorPalette.selectableColumns.color,
            paddingLeft: "2px",
            //minWidth: "140px"
        },
        lookup: {
            "-": "-",
            "bi:": "bi: Base Index",
            "dg:": "dg: Desired Gains Index",
            //"ex:": "ex: Selection on Extremes",
            "ic:": "ic: Independent Culling",
            "sh:": "sh: Smith-Hazel Index",
            "ts:": "ts: Tandem Selection",
        },
        modelName: "Trait",
        //helpText: "This is column help text in popover."
    },
    recyclingValue: {
        columnName: "Recycling Value",
        verticalOrientation: true,
        //freeSolo: true,
        styles: {
            backgroundColor: gridColorPalette.selectableColumns.background,
            color: gridColorPalette.selectableColumns.color,
            paddingLeft: "2px",
            //minWidth: "120px"
        },
        lookup: {
            "-": "-",
            "a:": "a: Average",
            "b:": "b: BLUE",
            "bv:": "bv: BV",
            "g:": "g: GCA",
            "gb:": "gb: GEBV",
            "gc:": "gc: GEGCA",
            "gg:": "gg: GEGV",
            "gp:": "gp: GPCP",
            "gs:": "gs: GESCA",
            "gv:": "gv: GV",
            "i:": "i: Single record",
            "s:": "s: SCA"
        },
        modelName: "Trait",
        //helpText: "This is column help text in popover."
    },
    recyclingSelection: {
        columnName: "Recycling Selection Method",
        verticalOrientation: true,
        //freeSolo: true,
        styles: {
            backgroundColor: gridColorPalette.selectableColumns.background,
            color: gridColorPalette.selectableColumns.color,
            paddingLeft: "2px",
            //minWidth: "140px"
        },
        lookup: {
            "-": "-",
            "bi:": "bi: Base Index",
            "dg:": "dg: Desired Gains Index",
            "ex:": "ex: Selection on Extremes",
            "ic:": "ic: Independent Culling",
            "sh:": "sh: Smith-Hazel Index",
            "ts:": "ts: Tandem Selection",
        },
        modelName: "Trait",
        //helpText: "This is column help text in popover."
    },

    //Assumes a column as trait by default since its the only column with arrays
    defaultColumn: {
        verticalOrientation: false,
        styles: { backgroundColor: "#FFFFFF", borderLeft: "2px solid #aaa", maxWidth: "400px" },
        action: {
            type: "Remove",
            method: "handleConfirmRemoveTraits"
        },
        //Mark this to true for updating columns value like trait 
        isEditable: false,
        helpText: <div>1. column: Number of test locations per trait <br /> 2. column: Phenotype/Evaluation unit < br /> 3. column: Method of statistical evaluation < br /> 4. column: Advancement value < br /> 5. column: Advancement selection method < br /> 6. column: Recycling value< br /> 7. column: Recycling selection method</div >,
        //This model name is only used for rendering traits columns. No data transaction is being processed through this 
        modelName: "Traits"
    }
}

