//Action Types

//UserAuth & SignUp Action Types
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_RESPONSE = 'LOGIN_RESPONSE'
export const LOGOUT_RESPONSE = 'LOGOUT_RESPONSE'
export const VERIFY_USER_REQUEST = 'VERIFY_USER_REQUEST'
export const VERIFY_USER_RESPONSE = 'VERIFY_USER_RESPONSE'
export const SIGNUP_REQUEST = 'SIGNUP_REQUEST'
export const SIGNUP_RESPONSE = 'SIGNUP_RESPONSE'
export const EMAIL_VERIFICATION_REQUEST = 'EMAIL_VERIFICATION_REQUEST'
export const EMAIL_VERIFICATION_RESPONSE = 'EMAIL_VERIFICATION_RESPONSE'
export const ACCOUNT_APPROVAL_REQUEST = 'ACCOUNT_APPROVAL_REQUEST'
export const ACCOUNT_APPROVAL_RESPONSE = 'ACCOUNT_APPROVAL_RESPONSE'
export const SIGNUP_ERROR = 'SIGNUP_ERROR'
export const SIGNUP_FORM_VALIDATE = 'SIGNUP_FORM_VALIDATE'
export const FETCH_PROFILE_REQUEST = 'FETCH_PROFILE_REQUEST'
export const FETCH_PROFILE_RESPONSE = 'FETCH_PROFILE_RESPONSE'
export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST'
export const UPDATE_PROFILE_RESPONSE = 'UPDATE_PROFILE_RESPONSE'

//Change Password Action Types
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST'
export const CHANGE_PASSWORD_RESPONSE = 'CHANGE_PASSWORD_RESPONSE'
export const CHANGE_PASSWORD_RESET = 'CHANGE_PASSWORD_RESET'

//Password Recovery Action Types
export const PASSWORD_RECOVER_REQUEST = 'PASSWORD_RECOVER_REQUEST'
export const PASSWORD_RECOVER_RESPONSE = 'PASSWORD_RECOVER_RESPONSE'
export const PASSWORD_TOKEN_VERIFY_REQUEST = 'PASSWORD_TOKEN_VERIFY_REQUEST'
export const PASSWORD_TOKEN_VERIFY_RESPONSE = 'PASSWORD_TOKEN_VERIFY_RESPONSE'
export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST'
export const PASSWORD_RESET_RESPONSE = 'PASSWORD_RESET_RESPONSE'

//Notification Action Types
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION'
export const CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION'
export const RESET_NOTIFICATION = 'RESET_NOTIFICATION'

//Breeding Pipeline Action Types
export const FETCH_ALL_BREEDING_PIPELINE_REQUEST = 'FETCH_ALL_BREEDING_PIPELINE_REQUEST'
export const FETCH_ALL_BREEDING_PIPELINE_RESPONSE = 'FETCH_ALL_BREEDING_PIPELINE_RESPONSE'
export const LOADING_BREEDING_PIPELINE = "LOADING_BREEDING_PIPELINE"
export const LOADING_BREEDING_SCHEME = "LOADING_BREEDING_SCHEME"
export const CREATE_BREEDING_PIPELINE_RESPONSE = 'CREATE_BREEDING_PIPELINE_RESPONSE'
export const UPDATE_BREEDING_PIPELINE_RESPONSE = 'UPDATE_BREEDING_PIPELINE_RESPONSE'
export const CONFIRM_REMOVE_BREEDING_PIPELINE = 'CONFIRM_REMOVE_BREEDING_PIPELINE'
export const CANCEL_REMOVE_BREEDING_PIPELINE = 'CANCEL_REMOVE_BREEDING_PIPELINE'
export const ADD_RENDERED_MARKET_SEGMENTS = 'ADD_RENDERED_MARKET_SEGMENTS'
export const REMOVE_RENDERED_MARKET_SEGMENTS = 'REMOVE_RENDERED_MARKET_SEGMENTS'
export const ADD_RENDERED_BREEDING_SCHEMES = 'ADD_RENDERED_BREEDING_SCHEMES'
export const REMOVE_RENDERED_BREEDING_SCHEMES = 'REMOVE_RENDERED_BREEDING_SCHEMES'
export const ADD_RENDERED_PRODUCT_PROFILES = 'ADD_RENDERED_PRODUCT_PROFILES'
export const REMOVE_RENDERED_PRODUCT_PROFILES = 'REMOVE_RENDERED_PRODUCT_PROFILES'
export const REMOVE_BREEDING_PIPELINE_RESPONSE = 'REMOVE_BREEDING_PIPELINE_RESPONSE'

//Market Segment Action Types
export const FETCH_ALL_MARKET_SEGMENT_REQUEST = 'FETCH_ALL_MARKET_SEGMENT_REQUEST'
export const FETCH_ALL_MARKET_SEGMENT_RESPONSE = 'FETCH_ALL_MARKET_SEGMENT_RESPONSE'
export const LOADING_MARKET_SEGMENT = 'LOADING_MARKET_SEGMENT'
export const CREATE_MARKET_SEGMENT_RESPONSE = 'CREATE_MARKET_SEGMENT_RESPONSE'
export const UPDATE_MARKET_SEGMENT_RESPONSE = 'UPDATE_MARKET_SEGMENT_RESPONSE'
export const CONFIRM_DUPLICATE_MARKET_SEGMENT = 'CONFIRM_DUPLICATE_MARKET_SEGMENT'
export const CANCEL_DUPLICATE_MARKET_SEGMENT = 'CANCEL_DUPLICATE_MARKET_SEGMENT'
export const DUPLICATE_MARKET_SEGMENT_RESPONSE = 'DUPLICATE_MARKET_SEGMENT_RESPONSE'
export const CONFIRM_REMOVE_MARKET_SEGMENT = 'CONFIRM_REMOVE_MARKET_SEGMENT'
export const CANCEL_REMOVE_MARKET_SEGMENT = 'CANCEL_REMOVE_MARKET_SEGMENT'
export const REMOVE_MARKET_SEGMENT_RESPONSE = 'REMOVE_MARKET_SEGMENT_RESPONSE'
export const REFRESH_MARKET_SEGMENT_RESPONSE = 'REFRESH_MARKET_SEGMENT_RESPONSE'

//Product Profile Action Types
export const FETCH_ALL_PRODUCT_PROFILE_REQUEST = 'FETCH_ALL_PRODUCT_PROFILE_REQUEST'
export const FETCH_ALL_PRODUCT_PROFILE_RESPONSE = 'FETCH_ALL_PRODUCT_PROFILE_RESPONSE'
export const LOADING_PRODUCT_PROFILE = 'LOADING_PRODUCT_PROFILE'
export const CREATE_PRODUCT_PROFILE_RESPONSE = 'CREATE_PRODUCT_PROFILE_RESPONSE'
export const UPDATE_PRODUCT_PROFILE_RESPONSE = 'UPDATE_PRODUCT_PROFILE_RESPONSE'
export const CONFIRM_DUPLICATE_PRODUCT_PROFILE = 'CONFIRM_DUPLICATE_PRODUCT_PROFILE'
export const CANCEL_DUPLICATE_PRODUCT_PROFILE = 'CANCEL_DUPLICATE_PRODUCT_PROFILE'
export const DUPLICATE_PRODUCT_PROFILE_RESPONSE = 'DUPLICATE_PRODUCT_PROFILE_RESPONSE'
export const CONFIRM_REMOVE_PRODUCT_PROFILE = 'CONFIRM_REMOVE_PRODUCT_PROFILE'
export const CANCEL_REMOVE_PRODUCT_PROFILE = 'CANCEL_REMOVE_PRODUCT_PROFILE'
export const REMOVE_PRODUCT_PROFILE_RESPONSE = 'REMOVE_PRODUCT_PROFILE_RESPONSE'
export const REFRESH_PRODUCT_PROFILE_RESPONSE = 'REFRESH_PRODUCT_PROFILE_RESPONSE'

//Product Profile Trait Action Types
export const FETCH_ALL_PRODUCT_PROFILE_TRAIT_REQUEST = 'FETCH_ALL_PRODUCT_PROFILE_TRAIT_REQUEST'
export const FETCH_ALL_PRODUCT_PROFILE_TRAIT_RESPONSE = 'FETCH_ALL_PRODUCT_PROFILE_TRAIT_RESPONSE'
export const LOADING_PRODUCT_PROFILE_TRAIT = 'LOADING_PRODUCT_PROFILE_TRAIT'
export const CREATE_PRODUCT_PROFILE_TRAIT_RESPONSE = 'CREATE_PRODUCT_PROFILE_TRAIT_RESPONSE'
export const UPDATE_PRODUCT_PROFILE_TRAIT_RESPONSE = 'UPDATE_PRODUCT_PROFILE_TRAIT_RESPONSE'
export const CONFIRM_DUPLICATE_PRODUCT_PROFILE_TRAIT = 'CONFIRM_DUPLICATE_PRODUCT_PROFILE_TRAIT'
export const CANCEL_DUPLICATE_PRODUCT_PROFILE_TRAIT = 'CANCEL_DUPLICATE_PRODUCT_PROFILE_TRAIT'
export const DUPLICATE_PRODUCT_PROFILE_TRAIT_RESPONSE = 'DUPLICATE_PRODUCT_PROFILE_TRAIT_RESPONSE'
export const CONFIRM_REMOVE_PRODUCT_PROFILE_TRAIT = 'CONFIRM_REMOVE_PRODUCT_PROFILE_TRAIT'
export const CANCEL_REMOVE_PRODUCT_PROFILE_TRAIT = 'CANCEL_REMOVE_PRODUCT_PROFILE_TRAIT'
export const REMOVE_PRODUCT_PROFILE_TRAIT_RESPONSE = 'REMOVE_PRODUCT_PROFILE_TRAIT_RESPONSE'
export const REFRESH_PRODUCT_PROFILE_TRAIT_RESPONSE = 'REFRESH_PRODUCT_PROFILE_TRAIT_RESPONSE'

//Breeding Scheme Action Types
export const FETCH_ALL_BREEDING_SCHEME_REQUEST = 'FETCH_ALL_BREEDING_SCHEME_REQUEST'
export const FETCH_ALL_BREEDING_SCHEME_RESPONSE = 'FETCH_ALL_BREEDING_SCHEME_RESPONSE'
export const CREATE_BREEDING_SCHEME_RESPONSE = 'CREATE_BREEDING_SCHEME_RESPONSE'
export const UPDATE_BREEDING_SCHEME_RESPONSE = 'UPDATE_BREEDING_SCHEME_RESPONSE'
export const CONFIRM_DUPLICATE_BREEDING_SCHEME = 'CONFIRM_DUPLICATE_BREEDING_SCHEME'
export const CANCEL_DUPLICATE_BREEDING_SCHEME = 'CANCEL_DUPLICATE_BREEDING_SCHEME'
export const DUPLICATE_BREEDING_SCHEME_RESPONSE = 'DUPLICATE_BREEDING_SCHEME_RESPONSE'
export const CONFIRM_REMOVE_BREEDING_SCHEME = 'CONFIRM_REMOVE_BREEDING_SCHEME'
export const CANCEL_REMOVE_BREEDING_SCHEME = 'CANCEL_REMOVE_BREEDING_SCHEME'
export const REMOVE_BREEDING_SCHEME_RESPONSE = 'REMOVE_BREEDING_SCHEME_RESPONSE'
export const REFRESH_BREEDING_SCHEME_RESPONSE = 'REFRESH_BREEDING_SCHEME_RESPONSE'

//Breeding Scheme Settings Action Types
//export const FETCH_BREEDING_SCHEME_SETTINGS_REQUEST = 'FETCH_BREEDING_SCHEME_SETTINGS_REQUEST'
export const FETCH_BREEDING_SCHEME_SETTINGS_RESPONSE = 'FETCH_BREEDING_SCHEME_SETTINGS_RESPONSE'
export const LOADING_BREEDING_SCHEME_SETTINGS = 'LOADING_BREEDING_SCHEME_SETTINGS'
export const UPDATE_BREEDING_SCHEME_SETTINGS_RESPONSE = 'UPDATE_BREEDING_SCHEME_SETTINGS_RESPONSE'

//Breeding Stage Action Types
export const FETCH_BREEDING_PIPELINE_RESPONSE = 'FETCH_BREEDING_PIPELINE_RESPONSE'
export const FETCH_ALL_BREEDING_STAGE_REQUEST = 'FETCH_ALL_BREEDING_STAGE_REQUEST'
export const FETCH_ALL_BREEDING_STAGE_RESPONSE = 'FETCH_ALL_BREEDING_STAGE_RESPONSE'
export const UPDATE_BREEDING_STAGE_CELLS_REQUEST = 'UPDATE_BREEDING_STAGE_CELLS_REQUEST'
export const UPDATE_BREEDING_STAGE_CELLS_RESPONSE = 'UPDATE_BREEDING_STAGE_CELLS_RESPONSE'
export const CANCEL_UPDATE_BREEDING_STAGE_CELLS_REQUEST = 'CANCEL_UPDATE_BREEDING_STAGE_CELLS_REQUEST'
export const LOADING_BREEDING_STAGE = "LOADING_BREEDING_STAGE"
export const REMOVE_LOADING_BREEDING_STAGE = "REMOVE_LOADING_BREEDING_STAGE"
export const LOADING_BREEDING_STAGE_EXPORT = "LOADING_BREEDING_STAGE_EXPORT"
export const REMOVE_LOADING_BREEDING_STAGE_EXPORT = "REMOVE_LOADING_BREEDING_STAGE_EXPORT"
export const CREATE_BREEDING_POOL_RESPONSE = 'CREATE_BREEDING_POOL_RESPONSE'
export const CREATE_BREEDING_STAGE_RESPONSE = 'CREATE_BREEDING_STAGE_RESPONSE'
export const UPDATE_BREEDING_STAGE_RESPONSE = 'UPDATE_BREEDING_STAGE_RESPONSE'
export const CLEANUP_BREEDING_STAGE_REQUEST = 'CLEANUP_BREEDING_STAGE_REQUEST'
export const OPEN_ADD_GENOTYPE_TECHNOLOGY = 'OPEN_ADD_GENOTYPE_TECHNOLOGY'
export const ADD_GENOTYPE_TECHNOLOGY_RESPONSE = 'ADD_GENOTYPE_TECHNOLOGY_RESPONSE'
export const CANCEL_ADD_GENOTYPE_TECHNOLOGY = 'CANCEL_ADD_GENOTYPE_TECHNOLOGY'
export const OPEN_ADD_MARKER_TRAITS = 'OPEN_ADD_MARKER_TRAITS'
export const ADD_MARKER_TRAITS_RESPONSE = 'ADD_MARKER_TRAITS_RESPONSE'
export const CANCEL_ADD_MARKER_TRAITS = 'CANCEL_ADD_MARKER_TRAITS'
export const OPEN_ADD_PHENOTYPE_TRAITS = 'OPEN_ADD_PHENOTYPE_TRAITS'
export const ADD_PHENOTYPE_TRAITS_RESPONSE = 'ADD_PHENOTYPE_TRAITS_RESPONSE'
export const CANCEL_ADD_PHENOTYPE_TRAITS = 'CANCEL_ADD_PHENOTYPE_TRAITS'
export const OPEN_ADD_TRAITS = 'OPEN_ADD_TRAITS'
export const ADD_TRAITS_RESPONSE = 'ADD_TRAITS_RESPONSE'
export const CANCEL_ADD_TRAITS = 'CANCEL_ADD_TRAITS'
export const OPEN_UPDATE_TRAITS = 'OPEN_UPDATE_TRAITS'
export const UPDATE_TRAITS_RESPONSE = 'UPDATE_TRAITS_RESPONSE'
export const CANCEL_UPDATE_TRAITS = 'CANCEL_UPDATE_TRAITS'
export const CONFIRM_DUPLICATE_STAGE = 'CONFIRM_DUPLICATE_STAGE'
export const CANCEL_DUPLICATE_STAGE = 'CANCEL_DUPLICATE_STAGE'
export const DUPLICATE_STAGE_RESPONSE = 'DUPLICATE_STAGE_RESPONSE'
export const CONFIRM_REMOVE_ITEM = 'CONFIRM_REMOVE_ITEM'
export const CANCEL_REMOVE_ITEM = 'CANCEL_REMOVE_ITEM'
export const REMOVE_ITEM_RESPONSE = 'REMOVE_ITEM_RESPONSE'
export const REFRESH_BREEDING_STAGE_RESPONSE = 'REFRESH_BREEDING_STAGE_RESPONSE'
export const BREEDING_STAGE_TOGGLE_COLUMN = 'BREEDING_STAGE_TOGGLE_COLUMN'

// Quick Note Action Types
export const OPEN_QUICK_NOTE = 'OPEN_QUICK_NOTE'
export const CLOSE_QUICK_NOTE = 'CLOSE_QUICK_NOTE'

//Breeding Scheme Blueprint Action Types
export const FETCH_ONE_BREEDING_SCHEME_BLUEPRINT_REQUEST = 'FETCH_ONE_BREEDING_SCHEME_BLUEPRINT_REQUEST'
export const FETCH_BREEDING_PIPELINE_FLOWCHART_RESPONSE = 'FETCH_BREEDING_PIPELINE_FLOWCHART_RESPONSE'
export const FETCH_ONE_BREEDING_SCHEME_BLUEPRINT_RESPONSE = 'FETCH_ONE_BREEDING_SCHEME_BLUEPRINT_RESPONSE'
export const LOADING_BREEDING_SCHEME_BLUEPRINT = "LOADING_BREEDING_SCHEME_BLUEPRINT"
export const SAVE_BREEDING_SCHEME_BLUEPRINT_RESPONSE = 'SAVE_BREEDING_SCHEME_BLUEPRINT_RESPONSE'
export const REMOVE_BREEDING_SCHEME_BLUEPRINT_RESPONSE = 'REMOVE_BREEDING_SCHEME_BLUEPRINT_RESPONSE'

//Users Action Types
export const FETCH_ALL_USERS_REQUEST = 'FETCH_ALL_USERS_REQUEST'
export const FETCH_ALL_USERS_RESPONSE = 'FETCH_ALL_USERS_RESPONSE'
export const LOADING_USERS = "LOADING_USERS"
export const UPDATE_USER_RESPONSE = 'UPDATE_USER_RESPONSE'
export const CONFIRM_REMOVE_USER = 'CONFIRM_REMOVE_USER'
export const CANCEL_REMOVE_USER = 'CANCEL_REMOVE_USER'
export const REMOVE_USER_RESPONSE = 'REMOVE_USER_RESPONSE'
export const CONFIRM_REACTIVATE_USER = 'CONFIRM_REACTIVATE_USER'
export const CANCEL_REACTIVATE_USER = 'CANCEL_REACTIVATE_USER'
export const REACTIVATE_USER_RESPONSE = 'REACTIVATE_USER_RESPONSE'
export const CONFIRM_APPROVE_USER = 'CONFIRM_REACTIVATE_USER'
export const CANCEL_APPROVE_USER = 'CANCEL_REACTIVATE_USER'
export const APPROVE_USER_RESPONSE = 'APPROVE_USER_RESPONSE'
export const REFRESH_USER_RESPONSE = 'REFRESH_USER_RESPONSE'

//Share Action Types
export const FETCH_ALL_UNSHARED_USERS_REQUEST = 'FETCH_ALL_UNSHARED_USERS_REQUEST'
export const FETCH_ALL_UNSHARED_USERS_RESPONSE = 'FETCH_ALL_UNSHARED_USERS_RESPONSE'
export const FETCH_ALL_SHARED_USERS_REQUEST = 'FETCH_ALL_SHARED_USERS_REQUEST'
export const FETCH_ALL_SHARED_USERS_RESPONSE = 'FETCH_ALL_SHARED_USERS_RESPONSE'