import { useSelector } from 'react-redux'
import { selectBreedingStagesIsExporting } from '../../../selectors/breedingStageSelector'
import TableCell from '@material-ui/core/TableCell'
import GridTextField from '../../atoms/GridTextField'
import GridSelectField from '../../atoms/GridSelectField'
import GridSelectMultiField from '../../atoms/GridSelectMultiField'
import GridAutocompleteField from '../../atoms/GridAutocompleteField'
import GridAutocompleteMultiField from '../../atoms/GridAutocompleteMultiField'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
    root: {
        '& .MuiInputBase-root': {
            color: "inherit",
            fontWeight: "500"
        },
        border: "1px solid #b3b3b3",
        '& input, div': {
            fontSize: "12px !important",
            textAlign: "center"
        },
        '& div > .MuiSelect-select.MuiSelect-select': {
            paddingRight: "18px"
        },
        '& div:after, div:before': {
            borderBottom: "0px !important"
        },
        '& .Mui-disabled': {
            color: "#000",
            fontWeight: 500
        },
        '& svg': {
            color: "#e1e1e1",
            width: "0.8em",
            height: "0.8em"
        }
    }
});

const GridEditableCell = (props) => {
    const exportState = useSelector(selectBreedingStagesIsExporting)
    const borderStyle = {
        size: "1px",
        color: "#b3b3b3"
    }

    const navigateCells = (e) => {
        switch (e.code) {
            case "ArrowLeft":
                const focusOnPreviousElement = (startElement) => {
                    const previousElement = startElement.parentElement.parentElement.previousElementSibling
                    if (previousElement) {
                        const previousELementInput = previousElement.getElementsByTagName("input")
                        if (previousELementInput.length) {
                            previousELementInput[0].disabled ? focusOnPreviousElement(previousELementInput[0]) : previousELementInput[0].focus()
                        }
                    }
                }
                focusOnPreviousElement(e.target)
                break;
            case 'ArrowUp':
                const tCellIdx = e.target.parentElement.parentElement.cellIndex
                const topRow = e.target.parentElement.parentElement.parentElement.previousElementSibling
                if (topRow && topRow.cells) {
                    const topElement = topRow.cells[tCellIdx]
                    const topELementInput = topElement.getElementsByTagName("input")
                    topELementInput.length && topELementInput[0].focus()
                }
                break;
            case 'ArrowRight':
                const focusOnNextElement = (startElement) => {
                    const nextElement = startElement.parentElement.parentElement.nextElementSibling
                    if (nextElement) {
                        const nextELementInput = nextElement.getElementsByTagName("input")
                        if (nextELementInput.length) {
                            nextELementInput[0].disabled ? focusOnNextElement(nextELementInput[0]) : nextELementInput[0].focus()
                        }
                    }
                }
                focusOnNextElement(e.target)
                break;
            case 'ArrowDown':
                const bcellIdx = e.target.parentElement.parentElement.cellIndex
                const bottomRow = e.target.parentElement.parentElement.parentElement.nextElementSibling
                if (bottomRow && bottomRow.cells) {
                    const bottomElement = bottomRow.cells[bcellIdx]
                    if (bottomElement) {
                        const bottomELementInput = bottomElement.getElementsByTagName("input")
                        bottomELementInput.length && bottomELementInput[0].focus()
                    }
                }
                break;
            default:
                break;
        }
    }

    const handleCellFocus = (e) => {
        if (e.target.tagName && e.target.tagName.toLowerCase() === "input")
            e.target.parentElement.parentElement.parentElement.style.border = "2px solid #152733"
    }

    const handleCellBlur = (e) => {
        if (e.target.tagName && e.target.tagName.toLowerCase() === "input")
            e.target.parentElement.parentElement.parentElement.style.border = "none"
    }
    const classes = useStyles()
    return (
        <TableCell
            className={classes.root}
            data-a-h="center"
            data-fill-color={props.columnDef.cellStyle && props.columnDef.cellStyle.backgroundColor ? props.columnDef.cellStyle.backgroundColor.substring(1) : ""}
            data-b-a-s="thin"
            data-b-a-c="b3b3b3"
            data-f-sz="12"
            data-f-color={props.columnDef.cellStyle && props.columnDef.cellStyle.color ? props.columnDef.cellStyle.color.substring(1) : ""}
            align="center"
            variant="body"
            padding="none"
            size="small"
            onFocus={handleCellFocus}
            onBlur={handleCellBlur}
            onKeyDown={navigateCells}
            style={{ ...props.columnDef.cellStyle, borderColor: borderStyle.color, borderWidth: borderStyle.size }}
        >
            {exportState
                ? props.value ? props.value : props.rowData[props.columnDef.field]
                : (props.columnDef.lookup ?
                    (props.columnDef.freeSolo ?
                        (props.columnDef.multi ? <GridAutocompleteMultiField {...props} /> : <GridAutocompleteField {...props} />) :
                        props.columnDef.multi ? <GridSelectMultiField {...props} /> :
                            <GridSelectField {...props} />) : <GridTextField {...props} />)}
        </TableCell>
    )
}

export default GridEditableCell