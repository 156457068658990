import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectQuickNoteForm } from '../../../selectors/breedingStageFormSelector'
import { loadQuickNote } from '../../../actions/breedingStage'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { closeQuickNote } from '../../../actions/breedingStage'
import Paper from '@material-ui/core/Paper';
import NotesIcon from '@material-ui/icons/Notes';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Draggable from 'react-draggable';

const useStyles = makeStyles((theme) => ({
    form: {
        minWidth: "350px"
    },
    textarea: {
        background: "transparent"
    },
    dialogTitle: {
        padding: "10px 20px",
        marginBottom: "0px",
        backgroundColor: "rgb(242, 241, 242)",
        borderBottom: "1px solid #e3e3e3"
    },
    dialogContent: {
        padding: "0px 5px 0px 20px",
        background: "url(/notesbg.png) repeat-y",
    },
    dialogActions: {
        backgroundColor: "rgb(242, 241, 242)",
        cursor: "move"
    },
    closeButton: {
        position: 'absolute',
        right: "5px",
        top: "0px",
    }
}))

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} style={{ margin: 0, maxHeight: "100%" }} />
        </Draggable>
    );
}

const QuickNoteForm = () => {
    const dispatch = useDispatch()
    const quickNoteFormState = useSelector(selectQuickNoteForm)
    const [poolForm, setPoolForm] = useState({
        notes: "",
        isLoading: true
    })

    useEffect(() => {
        async function fetchPool(id) {
            const data = await dispatch(loadQuickNote(id))
            return data
        }
        if (quickNoteFormState?.PoolId) {
            fetchPool(quickNoteFormState?.PoolId).then((result) => {
                setPoolForm({
                    isLoading: false,
                    notes: result.data.notes || ""
                })
            }).catch((error) => console.log(error))

        }
    }, [quickNoteFormState])

    const handleChange = (e) => {
        setPoolForm({
            ...poolForm,
            [e.target.name]: e.target.value
        })
    }

    const handleCloseQuickNotes = () => {
        setPoolForm({
            ...poolForm,
            isLoading: true
        })
        dispatch(closeQuickNote(quickNoteFormState.PoolId, poolForm))
    }

    const classes = useStyles()

    return (
        <Dialog
            open={quickNoteFormState.open}
            aria-labelledby="quick-notes"
            aria-describedby="quick-notes-description"
            PaperComponent={PaperComponent}
            style={{ position: 'sticky', bottom: "50%" }}
            disableEnforceFocus
            disableBackdropClick
            hideBackdrop
        >
            <form className={classes.form} autoComplete="off">
                <DialogTitle className={classes.dialogTitle} style={{ cursor: 'move' }} id="draggable-dialog-title">
                    <Box style={{ gap: "4px" }} display="flex">
                        <NotesIcon /> Notes
                    </Box>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseQuickNotes}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <LinearProgress hidden={!poolForm.isLoading} color='secondary' variant='indeterminate' />
                <DialogContent className={classes.dialogContent}>
                    <TextField
                        name='notes'
                        id="standard-multiline-flexible"
                        multiline
                        disabled={poolForm.isLoading}
                        className={classes.textarea}
                        rows={7}
                        fullWidth
                        rowsMax={21}
                        inputProps={{ style: { fontSize: "0.85rem" } }}
                        value={poolForm.notes}
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions id="draggable-dialog-title" className={classes.dialogActions}></DialogActions>
            </form>
        </Dialog>
    )
}

export default QuickNoteForm;