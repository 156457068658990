import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(() => ({
    sortButtons: {
        '& button': {
            padding: "0px",
            height: "16px"
        }
    }
}))

const TableRowOrder = (props) => {
    const classes = useStyles()
    return (
        <Box textAlign="center">
            <Box display="inline" mr={0.5}>
                <b>{props.children}</b>
            </Box>
            <Box className={classes.sortButtons} display="inline-grid">
                <IconButton title="Move Up" disabled={props.isDisabled || props.order === 1} onClick={() => props.sort(props.order - 1, props.order, props.id, props.poolId)} color="secondary">
                    <ArrowDropUpIcon />
                </IconButton>
                <IconButton title="Move Down" disabled={props.isDisabled || props.order === props.total} onClick={() => props.sort(props.order + 1, props.order, props.id, props.poolId)} color={(props.isDisabled || props.order === props.total) ? "default" : "primary"}>
                    <ArrowDropDownIcon />
                </IconButton>
            </Box>
        </Box>
    )
}

export default TableRowOrder